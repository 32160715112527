import { useEffect, useState } from "react";
import { UserProfileDto } from "../../../../../application/features/user-profile-service/models/user-profile-dto";
import { useAppDispatch, useAppSelector } from "../../../../../application/store/useStore";
import { AssignDeviceOtpAsync, CreateDeviceOtpAsync } from "../../../../../application/features/device-service/deviceServiceThunk";
import { useForm, Controller } from "react-hook-form";
import { RootState } from "../../../../../application/store";
import { addWarningNotification } from "../../../../../application/features/common/notificationSlice";
import { MapDeviceDto } from "../../../../../application/features/device-service/models/MapDeviceDto";

interface ConsumerDashboardDeviceProps {
    onHide?(): void;
    onComplete?(): void;
    userprofile: UserProfileDto | undefined;
}

interface ImeiOtpForm {
    imei: string;
    otp: string;
}

const ConsumerDashboardDevice = ({ onHide, onComplete, userprofile }: ConsumerDashboardDeviceProps) => {
    const dispatch = useAppDispatch();
    const [step, setStep] = useState<'imei' | 'otp'>('imei');
    const [timer, setTimer] = useState(120); // 2 mins timer
    const [timerExpired, setTimerExpired] = useState(false);

    // Initialize react-hook-form
    const { handleSubmit, control, setValue, setError, clearErrors, formState: { errors } } = useForm<ImeiOtpForm>();

    // Timer countdown effect
    useEffect(() => {
        if (step === 'otp' && timer > 0) {
            const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
            return () => clearInterval(interval);
        } else if (timer === 0) {
            setTimerExpired(true);
            setStep('imei'); // Reset to IMEI step when timer expires
            setValue('imei', ''); // Clear the IMEI input field
            setValue('otp', '');  // Clear the OTP input field if needed
        }
    }, [step, timer]);

    // IMEI validation function
    const validateImei = (value: string) => {
        const imeiRegex = /^\d{15}$/;
        if (!imeiRegex.test(value)) {
            return "IMEI must be a 15-digit number.";
        }
        return true;
    };

    // Handle Connect with IMEI
    const handleConnect = async ({ imei }: ImeiOtpForm) => {
        try {
            // Clear errors
            clearErrors("imei");
            // Dispatch the API call and capture the response
            const response = await dispatch(CreateDeviceOtpAsync(imei)).unwrap(); // Ensure you use .unwrap() to access the payload directly if using Redux toolkit
          
            // On success
            setStep('otp');
            setTimer(120); // reset timer for OTP
            setTimerExpired(false);
            // Reset the OTP input field
            setValue('imei', ''); // Clear the OTP input when transitioning
        } catch (error) {
            // console.error("Error registering device please contact Support");
            dispatch(addWarningNotification("Error registering device please contact Support"));
        }
    };

    // Handle Verify OTP
    const handleVerify = async ({ otp }: ImeiOtpForm) => {
        try {
            if (userprofile && userprofile.sid) {
                // Dispatch the API call
                // Construct the MapDeviceDto object
                const mapDeviceData: MapDeviceDto = {
                    first_name: userprofile?.first_name ?? "", // Replace with actual user profile data
                    last_name: userprofile?.last_name ?? "",    // Replace with actual user profile data
                    assigned_user_sid: userprofile?.sid ?? "", // Replace with actual user SID
                    setup_date: new Date(),
                    sid: "" // Replace this with the actual device SID if available
                };

                // Dispatch the API call with both otp and mapDeviceData
                await dispatch(AssignDeviceOtpAsync({ otp, data: mapDeviceData }));
                setValue('otp', ''); // Clear the OTP input when transitioning
                if (onComplete) onComplete(); // Call onComplete after successful verification
            } else {
                dispatch(addWarningNotification("There is issue with User registration"));
            }

        } catch (error) {
            console.error("Failed to verify OTP", error);
        }
    };

    // Handle Resend OTP
    const handleResendOtp = () => {
        setTimer(120);
        setTimerExpired(false);
    };

    return (
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card mb-3">
                    <div className="card-body">
                        {step === 'imei' ? (
                            <>
                                <h5>Please turn on your watch and enter the IMEI number from the box:</h5>
                                <form onSubmit={handleSubmit(handleConnect)}>
                                    <Controller
                                        name="imei"
                                        control={control}
                                        rules={{
                                            required: "IMEI is required.",
                                            validate: validateImei
                                        }}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className={`form-control mb-3 ${errors.imei ? 'is-invalid' : ''}`}
                                                placeholder="Enter IMEI number"
                                            />
                                        )}
                                    />
                                    {errors.imei && <div className="text-danger">{errors.imei.message}</div>}

                                    <button type="submit" className="btn btn-primary" disabled={!!errors.imei}>
                                        Connect
                                    </button>
                                </form>
                            </>
                        ) : (
                            <>
                                <h5>Enter the OTP from your watch:</h5>
                                <form onSubmit={handleSubmit(handleVerify)}>
                                    <Controller
                                        name="otp"
                                        control={control}
                                        rules={{ required: "OTP is required." }}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className={`form-control mb-3 ${errors.otp ? 'is-invalid' : ''}`}
                                                placeholder="Enter OTP"
                                            />
                                        )}
                                    />
                                    {errors.otp && <div className="text-danger">{errors.otp.message}</div>}

                                    <button type="submit" className="btn btn-success" >
                                        {/* disabled={!timer || !!errors.otp} */}
                                        Verify OTP
                                    </button>

                                    <div className="mt-2">
                                        Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                                    </div>

                                    {/* {timerExpired && (
                                        <div className="mt-2">
                                            <button className="btn btn-warning" onClick={handleResendOtp}>
                                                Resend OTP
                                            </button>
                                            <p className="text-danger">OTP expired! Please request a new one.</p>
                                        </div>
                                    )} */}
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConsumerDashboardDevice;
