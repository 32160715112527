import { useEffect, useState } from "react";
import './style.css';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Tab, Tabs } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import { RootState } from "../../../../application/store";
import { getProfileAsync, getProfileByEmailAsync } from "../../../../application/features/user-profile-service/userProfileServiceThunk";
import { getDeviceByUserIdAsync } from "../../../../application/features/device-service/deviceServiceThunk"
import ClientOverviewPage from "../../portal/client-users/client-detail/over-view-client";
import HealthMetricsPage from "../../portal/client-users/client-detail/health-metrics";
import ClientAlertsPage from "../../portal/client-users/client-detail/client-alerts";
import ClientMedicationPage from "../../portal/client-users/client-detail/client-medication";
import MymonXDialog from "../../../components/mymonx-dialog";
import CreateClientUserForm from "../../portal/client-users/create-update-form/create-client-user-form";
import { addWarningNotification } from "../../../../application/features/common/notificationSlice";
import ClientMonthlyReportsPage from "../../portal/client-users/client-detail/client-monthly-reports";
import { getContactsAsync } from "../../../../application/features/contact-service/contactServiceThunk";
import ClientSummaryPage from "../../portal/client-users/client-detail/client-summary";
import ConsumerDashboardDevice from "./device";


const ConsumerDashboard = () => {
    const { user_profile, userAlerts } = useAppSelector((state: RootState) => state.userProfileService);
    const { device } = useAppSelector((state: RootState) => state.deviceService);
    const { currentUser } = useAppSelector((state: RootState) => state.securityService);
    const { contactList } = useAppSelector((state: RootState) => state.contactService);
    const [activeTab, setActiveTab] = useState('monthlyReports'); // Set default tab here
    const [isProfileCompleted, setIsProfileCompleted] = useState<boolean>(true);


    const dispatch = useAppDispatch();

    useEffect(() => {

        if (currentUser && currentUser.id) {
            dispatch(getProfileByEmailAsync(currentUser?.email ?? ''))
                .then((response) => {

                    if (response.payload) {
                        setIsProfileCompleted(true);
                    } else {
                        setIsProfileCompleted(false);
                    }
                })
                .catch((error) => {
                });
        }
    }, [dispatch, currentUser])
    useEffect(() => {

        if (user_profile && user_profile.sid) {
            dispatch(getContactsAsync(user_profile.sid));
            dispatch(getDeviceByUserIdAsync({ user_id: user_profile.sid }))
        }
    }, [dispatch, user_profile])
    const handleClose = () => {
        setIsProfileCompleted(false); // This can be any custom logic you want
        dispatch(addWarningNotification("Please complete your profile...!"));
    };
    return (
        <>
            <div className="user-profile">
                {!user_profile || !user_profile.first_name || !user_profile.sid ? (
                    <h3>No Account exists</h3>
                ) : (
                    <>
                        <h3>
                            {`${user_profile?.first_name}, ${user_profile?.last_name} 
                            ${user_profile?.gender.toLowerCase() === "male" ? '(Mr.)' : '(Ms.)'}`}
                        </h3>
                        <div className="tab-container mt-4">
                            <Tabs
                                defaultActiveKey="monthlyReports"
                                id="justify-tab-example"
                                className="tabs flex-wrap mb-3"
                                justify
                                onSelect={(selectedTab) => setActiveTab(selectedTab ?? '')} // Detect tab change
                            >
                                <Tab eventKey="monthlyReports" title="Monthly Reports">
                                    <ClientMonthlyReportsPage key={`monthly reports`} user_profile_sid={user_profile?.sid} />
                                </Tab>
                                <Tab eventKey="healthMetrics" title="Health Insights">
                                    <HealthMetricsPage key={`health metrics`} id={user_profile?.sid} />
                                </Tab>
                                <Tab eventKey="healthSummary" title="Health Summary">
                                    {activeTab === 'healthSummary' && (
                                        <ClientSummaryPage key={`health summary`} user_sid={user_profile?.sid} />
                                    )}
                                </Tab>
                                <Tab eventKey="medical" title="Medical">
                                    <ClientMedicationPage key={`client medication`} user_sid={user_profile?.sid} />
                                </Tab>
                                <Tab eventKey="alertsAndIncidents" title="Alerts and Health Incidents">
                                    <ClientAlertsPage key={`alerts-page-consume`} id={user_profile?.sid} />
                                </Tab>
                                <Tab eventKey="overview" title="Profile">
                                    {user_profile && userAlerts && userAlerts.data && (
                                        <ClientOverviewPage
                                            client={user_profile}
                                            userAlert={userAlerts.data[0]}
                                            device={device}
                                            contactList={contactList}
                                        />
                                    )}
                                </Tab>
                            </Tabs>
                        </div>
                    </>
                )}
            </div>

            <MymonXDialog
                title="Client Form"
                show={!isProfileCompleted}
                size="xl"
                onHide={handleClose}
            >
                <CreateClientUserForm
                    onHide={handleClose}
                    onComplete={() => {
                        setIsProfileCompleted(true);
                        getProfileByEmailAsync(currentUser?.email || '')
                    }}
                    emailconsumer={currentUser?.email || ''}
                />
            </MymonXDialog>


        </>
    );

}

export default ConsumerDashboard;