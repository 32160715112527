import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './application/store';
import * as serviceWorkerRegistration from '../src/serviceWorkerRegistration'; 

// Register service worker to enable PWA
// Function to check if the user is on a mobile device
const isMobileDevice = () => {
  return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
};


// Register the service worker conditionally based on the device
if (isMobileDevice()) {
  // Only register service worker for mobile devices
  serviceWorkerRegistration.register();
  localStorage.setItem('isMobile', "true");
} else {
  // Optionally, unregister service worker for non-mobile devices
  serviceWorkerRegistration.unregister(); 
  localStorage.setItem('isMobile', "false");
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
     <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
 
);

reportWebVitals();
