import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../application/store/useStore";
import { confirmEmailAsync, loginAsync, resendConfirmationEmailAsync } from "../../../../application/features/security-service/securityServiceThunk";
import { LoginCredentialsDto } from "../../../../application/features/security-service/models/login-credentials-dto";
import { Button } from "react-bootstrap";
import { ResendConfirmationEmailDto } from "../../../../application/features/security-service/models/resend-confirmation-email-dto";
import { addSuccessNotification } from "../../../../application/features/common/notificationSlice";
const EmailConfirmation = () => {
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showTokenMessage, setShowTokenMessage] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [pass, setPass] = useState<string>('');




    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const encodedData = queryParams.get('data');
        //const role = queryParams.get('role');
        if (token) {
            // Decode the data parameter if it exists
            let email = '';
            let password = '';
            if (encodedData) {
                const decodedData = atob(encodedData);
                [email, password] = decodedData.split('@@@');
                setEmail(email);
                setPass(pass);
            }

            // Create a temporary object that matches the LoginCredentialsDto interface


            dispatch(confirmEmailAsync(token)).then((res: any) => {

                if (res.payload && res.payload === 'Email verified') {
                    // Create an instance of LoginCredentialsDto
                    if (!encodedData) {
                        window.location.reload();
                    }
                    const loginCredentials =
                        { username: email, password: password } as LoginCredentialsDto;
                    dispatch(loginAsync(loginCredentials)).then((res: any) => {
                        if (res && res.payload) {
                            const baseUrl = window.location.protocol + "//" + window.location.host;
                            // Reload the page using the base URL
                            window.location.href = baseUrl;
                        }
                    })
                    //navigate('/auth/login');
                    // if(role && (role === 'user' || role === 'client')) {
                    //     navigate('/auth/login');
                    // } else {
                    //     navigate('/auth/complete-registeration');
                    // }
                }
                else if (res.payload && res.payload === 'Token expired') {
                    setShowTokenMessage(true);
                }
                else if (res.payload && res.payload === 'User not exists') {
                    setShowMessage(true);
                }
            })
        }
    }, [location.search]);

    const resendConfirmation = () => {
        const resendObj = { email: email, password: pass } as ResendConfirmationEmailDto;
        dispatch(resendConfirmationEmailAsync(resendObj)).then((res: any) => {
            if (res && res.payload && res.payload == "Success") {
                addSuccessNotification("Email has been sent")
            }
        });

    }
    return (
        <>
            <div>
                {showTokenMessage && (
                    <div>
                        <div className="alert alert-warning" role="alert">
                            Your token is expired. Request a new one.
                        </div>
                        <div>
                            <Button variant="primary" type="submit" onClick={resendConfirmation}>
                                Regenerate Token
                            </Button>
                        </div>
                    </div>
                )}
                {showMessage && (
                    <div className="alert alert-warning" role="alert">
                        User not exists.
                    </div>
                )}
            </div>
        </>
    )
}

export default EmailConfirmation;