import { useEffect, useState } from "react";
import { Col, Row, Card, Table, Badge, Form, Button } from "react-bootstrap";
import './style.css';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { BsFillPeopleFill } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import { TbUserExclamation } from "react-icons/tb";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import CustomModal from '../../../components/mymonx-Emergency-Alert';
import { RootState } from "../../../../application/store";
import { getClientDashboardAsync, getHighRiskProfilesAsync, getOrgAlertsAsync } from "../../../../application/features/user-profile-service/userProfileServiceThunk";
import { UserAlertGridDto, UserProfileGridDto } from "../../../../application/features/user-profile-service/models/user-profile-grid-dto";
import { capitalizeWords } from "../../../../application/common/helpers/text-helper";
import { getOrgFromJwt } from "../../../../application/common/helpers/jwt-helper";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import MymonXDialog from "../../../components/mymonx-dialog";
import LocalStorageService from "../../../../infrastructure/local-storage-service";
import { getOrganisationAsync, updateOrganisationAsync } from "../../../../application/features/org-service/orgServiceThunk";
import { getCountriesAsync } from "../../../../application/features/staticData-service/saticDataServiceThunk";
import { Controller, useForm } from "react-hook-form";
import { OrganisationDto } from "../../../../application/features/org-service/models/organisation-dto";
import { RegisterOrganisationResultDto } from "../../../../application/features/org-service/models/organisation-result-dto";
import MymonXValidation from "../../../components/mymonx-validation";
import MymonXReqAsteric from "../../../components/mymonx-req-asteric";
import { createComplianceRecordResultAsync, getActiveComplianceResultAsync } from "../../../../application/features/compliance-service/complianceServiceThunk";
import { ComplianceRecordDto, CreateComplianceRecordDto, GetComplianceDto } from "../../../../application/features/compliance-service/models/create-compliance-record-dto";


const Dashboard = () => {
    const dispatch = useAppDispatch();

    const { user_stats, high_risk_profiles, userAlerts, orgAlertRequest } = useAppSelector((state: RootState) => state.userProfileService);
    const { isShowCompleteRegisteration, currentUser } = useAppSelector((state: RootState) => state.securityService);
    const { complianceResult } = useAppSelector((state: RootState) => state.complianceService);
    const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
    const [showTerms, setShowTerms] = useState<boolean>(false);
    const [privacyAccepted, setPrivacyAccepted] = useState<string>("");
    const [termsAccepted, setTermsAccepted] = useState<string>("");
    const [privacyError, setPrivacyError] = useState<string>(""); // Added state for privacy error
    const [termsError, setTermsError] = useState<string>(""); // Added state for terms error
    const [privacyDocId, setPrivacyDocId] = useState<string>(""); // Added state for privacy error
    const [termsDocId, setTermsDocId] = useState<string>(""); // Added state for terms error
    const org_id = getOrgFromJwt();


    // complete registeration start //
    const navigate = useNavigate();
    const { organisation } = useAppSelector((state: RootState) => state.orgService);
    const { contry_data } = useAppSelector((state: RootState) => state.staticDataService);

    // complete registeration end //

    useEffect(() => {
        if (org_id !== undefined) {
            dispatch(getClientDashboardAsync(org_id));
            dispatch(getHighRiskProfilesAsync(org_id));
        }
    }, [dispatch, org_id])

    useEffect(() => {
        if (orgAlertRequest) {
            dispatch(getOrgAlertsAsync(orgAlertRequest));
        }
    }, [dispatch, orgAlertRequest])

    const getHealthStatus = (health_status: string) => {
        if (health_status.toLowerCase() === 'low risk')
            return 'success';
        return 'danger';
    }

    // complete registeration start //

    useEffect(() => {
        dispatch(getCountriesAsync({ page: 1, per_page: 100 }))
    }, [dispatch])

    const {
        control,
        formState: { errors },
        setValue,
        handleSubmit
    } = useForm<OrganisationDto>({
        defaultValues: organisation
    })

    useEffect(() => {
        const org_id = LocalStorageService.getOrg();

        if (org_id !== null && org_id !== '')
            dispatch(getOrganisationAsync(org_id))
    }, [dispatch]);

    useEffect(() => {
        if (organisation) {
            setValue('sid', organisation.sid);
            setValue('name', organisation.name);
            setValue('location', organisation.location);
            setValue('jurisdiction', organisation.jurisdiction);
            setValue('timezone',organisation.timezone);
        }
        if (isShowCompleteRegisteration) {
            const compliancePrivacyParams: GetComplianceDto = {
                jurisdiction: ''
            };
            dispatch(getActiveComplianceResultAsync(compliancePrivacyParams));
        }
    }, [organisation]);


    const onSubmit = (registerCredentials: OrganisationDto) => {
      
        if (privacyDocId === '') {
            setPrivacyError("Please accept the Privacy Policy");
        } else {
            setPrivacyError("");
        }

        if (termsDocId === '') {
            setTermsError("Please accept the Terms & Conditions");
        } else {
            setTermsError("");
        }

        if (privacyDocId === '' || termsDocId === '') {
            return;
        }

        if (currentUser !== undefined && currentUser.email != '') {
            const complianceRecords: CreateComplianceRecordDto[] = [];

            // Add privacy acceptance record

            const privacyRecord: CreateComplianceRecordDto = {
                sid: '',
                user_profile_sid: currentUser?.id,
                user_email: currentUser?.email,
                acceptance_datetime: new Date(),
                revocation_datetime: new Date(),
                compliance_document_sid: privacyDocId
            };
            complianceRecords.push(privacyRecord);
            const termsRecord: CreateComplianceRecordDto = {
                sid: '',
                user_profile_sid: currentUser?.id,
                user_email: currentUser?.email,
                acceptance_datetime: new Date(),
                revocation_datetime: new Date(),
                compliance_document_sid: termsDocId
            };
            complianceRecords.push(termsRecord);
            // Create the payload
            const complianceData: ComplianceRecordDto = {
                records: complianceRecords
            };
            // Dispatch the action with the payload
            dispatch(createComplianceRecordResultAsync(complianceData));

        }
        else {
            return;
        }
        dispatch(updateOrganisationAsync(registerCredentials)).then((res: any) => {
            if (res.payload) {
                const org = res.payload as RegisterOrganisationResultDto;
                if (org.organisation_id) {



                    LocalStorageService.removeOrg();
                    navigate('/portal/dashboard');
                    window.location.reload()
                }
            }
        })
    };
    const getPrivacyDocs = () => {

        setPrivacyError("");
        const docId = complianceResult?.find(result => result.compliance_type === 'PDP')?.sid;
        setPrivacyDocId(docId ?? '');
        setShowPrivacy(true);

    };
    const getTermsDocs = () => {
        setTermsError("");
        const docId = complianceResult?.find(result => result.compliance_type === 'TAC')?.sid;
        setTermsDocId(docId ?? '');
        setShowTerms(true);

    };
    const onSubmitCompliance = (isChecked: boolean, isTerms: boolean) => {
       
        if (isTerms) {
            if (complianceResult === undefined || complianceResult === null) {

                setTermsError("Please open Term's & Condition");
                setShowTerms(false);
                setTermsDocId('');
                return;
            }
            if (!isChecked) {
                setTermsError("Please accept the Terms & Conditions");
                setTermsDocId('');
            } else {
                setTermsError("");
                const docId = complianceResult?.find(result => result.compliance_type === 'TAC')?.sid;
                setTermsDocId(docId ?? '');

            }
            setShowTerms(false);
        }

        else {
            if (complianceResult === undefined || complianceResult === null) {
                setPrivacyError("Please open Privacy Policy");
                setShowPrivacy(false);
                setPrivacyDocId('');
                return;
            }
            if (!isChecked) {
                setPrivacyError("Please accept the Privacy Policy");
                setPrivacyDocId('');
            } else {
                setPrivacyError("");
                const docId = complianceResult?.find(result => result.compliance_type === 'PDP')?.sid;
                setPrivacyDocId(docId ?? '');

            }
            setShowPrivacy(false);
        }





    };
    const base64ToDataUrlas = (base64String: string): string => {
        return `data:application/pdf;base64,${base64String}`;
    };
    // complete registeration end //

    return (
        <>

            {/* <CustomModal /> */}
            <Row className="dashboard">
                <Col className="mb-3" col sm="6" md="6" lg="4" xl="4">
                    <Link to={`/portal/Client`} style={{ textDecoration: 'none' }}>
                        {<Card className="bg-info-subtle border-0 card h-100">
                            <Card.Body>

                                <Row className="align-items-center h-100" >
                                    <Col xs lg="8" >
                                        <BsFillPeopleFill size={48} />
                                        <h5 className="mt-2">Clients</h5>
                                    </Col>
                                    <Col xs lg="4" className="text-end" >
                                        {
                                            user_stats && <h1 className="mb-0">{user_stats.total_client.toString()}</h1>
                                        }

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        }</Link>
                </Col>
                <Col className="mb-3" col sm="6" md="6" lg="4" xl="4" >
                    <Card className="bg-warning-subtle border-0 card h-100">
                        <Card.Body>
                            <Row className="align-items-center h-100" >
                                <Col xs lg="8" >
                                    <FiAlertTriangle size={48} />
                                    <h5 className="mt-2">Client Alerts </h5>
                                </Col>
                                <Col xs lg="4" className="text-end" >
                                    {
                                        user_stats && <h1 className="mb-0">{
                                            user_stats.last_hour_alerts ?
                                                user_stats.last_hour_alerts.toString() : 0}</h1>
                                    }
                                    <small>(Last hour)</small>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="mb-3" col sm="6" md="6" lg="4" xl="4" >
                    <Link to={`/portal/Client`} style={{ textDecoration: 'none' }}>
                        {
                            <Card className="bg-danger-subtle border-0 card h-100">
                                <Card.Body>
                                    <Row className="align-items-center h-100" >
                                        <Col xs lg="8" >
                                            <TbUserExclamation size={48} />
                                            <h5 className="mt-2">High Risk Client</h5>
                                        </Col>
                                        <Col xs lg="4" className="text-end" >
                                            <h1 className="mb-0">
                                                {user_stats && user_stats.total_high_risk_client.toString()}
                                            </h1>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        }</Link>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={12} md={12} lg={12}>
                    <h4 className="border-b border-bottom mb-3">High Risk Clients</h4>
                    <Table responsive bordered className="text-nowrap">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Date of Birth</th>
                                <th>Health Status</th>
                                <th>Care Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            {high_risk_profiles &&
                                high_risk_profiles.map((row: UserProfileGridDto, index: number) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        {/* <td>{row.name}</td> */}
                                        <td key={`name-${index}`}>
                                            <Link to={`/portal/Client/${row.user_sid}`} > {
                                                `${row.name}`
                                            } </Link>
                                        </td>
                                        <td>{row.date_of_birth} {` (${row.years})`}</td>
                                        <td>
                                            <Badge bg={getHealthStatus(row.health_status)}>
                                                {capitalizeWords(row.health_status)}
                                            </Badge>
                                        </td>
                                        <td>
                                            {row.care_location}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={12} md={12} lg={12}>
                    <h4 className="border-b border-bottom mb-3">Client Alerts</h4>
                    <Table responsive bordered className="text-nowrap">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Time of Alert</th>
                                <th>Alert Type</th>
                                <th>Health Status</th>
                                <th>Care Location</th>
                                <th>Resolved?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userAlerts && userAlerts.data &&
                                userAlerts.data.map((row: UserAlertGridDto, index: number) => (
                                    <tr key={index}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>{row.name}</td>
                                        <td>{moment(row.alert_time).format('HH:mm')}</td>
                                        <td>{row.alert_type}</td>
                                        <td>
                                            <Badge bg="success">
                                                {capitalizeWords(row.health_status)}
                                            </Badge>
                                        </td>
                                        <td>{row.location}</td>
                                        <td>
                                            <Form.Check
                                                type={"checkbox"}
                                                id={`default-checkbox${row.sid}`}
                                                checked={row.resolved}
                                            //onChange={() => handleCheckboxChange(row)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <MymonXDialog
                title="Complete Registeration"
                show={isShowCompleteRegisteration}
                size="lg"
                onHide={() => console.log('hide')}
            >
                <Form onSubmit={handleSubmit(onSubmit)} id="completeRegisterForm">
                    <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.name`}>
                        <Form.Label column sm={2} md={5}>
                            Organisation Name:
                            <MymonXReqAsteric />
                        </Form.Label>
                        <Col sm={10} md={7}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'organisation name is required'
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Control type="text" onChange={onChange} value={value} placeholder="Organisation Name" />
                                )}
                            />
                            <MymonXValidation fieldError={errors.name} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.location`}>
                        <Form.Label column sm={2} md={5}>
                            Location:
                            <MymonXReqAsteric />
                        </Form.Label>
                        <Col sm={10} md={7}>
                            <Controller
                                name="location"
                                control={control}
                                rules={{ required: "Location is required" }}
                                render={({ field }) => (
                                    <Form.Select {...field} aria-label="Default select example">
                                        <option>Select Location</option>
                                        {contry_data.map((item: any) => (
                                            <option key={item.sid} value={item.sid}>{item.iso_name}</option>
                                        ))}
                                    </Form.Select>
                                )}
                            />
                            <MymonXValidation fieldError={errors.location} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.jurisdiction`}>
                        <Form.Label column sm={2} md={5}>
                            Data Protection Jurisdiction:
                            <MymonXReqAsteric />
                        </Form.Label>
                        <Col sm={10} md={7}>
                            <Controller
                                name="jurisdiction"
                                control={control}
                                rules={{ required: "Jurisdiction is required" }}
                                render={({ field }) => (
                                    <Form.Select {...field} aria-label="Default select example">
                                        <option>Select Jurisdiction</option>
                                        {contry_data.map((item: any) => (
                                            <option key={item.sid} value={item.sid}>{item.iso_name}</option>
                                        ))}
                                    </Form.Select>
                                )}
                            />
                            <MymonXValidation fieldError={errors.jurisdiction} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.address`}>
                        <Form.Label column sm={2} md={5}>
                            Address:
                            <MymonXReqAsteric />
                        </Form.Label>
                        <Col sm={10} md={7}>
                            <Controller
                                name="address"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Address is required'
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Control type="text" onChange={onChange} value={value} placeholder="Address" />
                                )}
                            />
                            <MymonXValidation fieldError={errors.address} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.postal_code`}>
                        <Form.Label column sm={2} md={5}>
                            Post Code:
                            <MymonXReqAsteric />
                        </Form.Label>
                        <Col sm={10} md={7}>
                            <Controller
                                name="postal_code"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Postal code is required'
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Control type="text" onChange={onChange} value={value} placeholder="Postal code" />
                                )}
                            />
                            <MymonXValidation fieldError={errors.postal_code} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.city`}>
                        <Form.Label column sm={2} md={5}>
                            City:
                            <MymonXReqAsteric />
                        </Form.Label>
                        <Col sm={10} md={7}>
                            <Controller
                                name="city"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'city is required'
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Control type="text" onChange={onChange} value={value} placeholder="City" />
                                )}
                            />
                            <MymonXValidation fieldError={errors.city} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.state`}>
                        <Form.Label column sm={2} md={5}>
                            State:
                            <MymonXReqAsteric />
                        </Form.Label>
                        <Col sm={10} md={7}>
                            <Controller
                                name="state"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'State is required'
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Control type="text" onChange={onChange} value={value} placeholder="State" />
                                )}
                            />
                            <MymonXValidation fieldError={errors.state} />
                        </Col>
                    </Form.Group>


                    {/* <Form.Group as={Row} className="mb-3" controlId="formTerms">
                        <Col sm={12} md={12}>
                            <Form.Check label="Please check to confirm that you agree to our 'Terms & Conditions'" />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formDataPrivacy">
                        <Col sm={12} md={12}>
                            <Form.Check label="Please check to confirm that ready our 'Data Privacy Policy'" />
                        </Col>
                    </Form.Group> */}

                    {/* <Form.Group as={Row} className="mb-3" controlId="formUpdate">
                        <Col sm={12} md={12}>
                            <Form.Check label="Please check to receive important updates and news about mymonX" />
                        </Col>
                    </Form.Group> */}
                    {/* <div className="text-center">
                        <a href="#" onClick={() => getTermsDocs()}>Terms & Conditions</a> | <a href="#" onClick={() => getPrivacyDocs()}>Privacy Policy</a>
                    </div> */}
                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label="I accept the Privacy Policy"
                            onChange={(e) => onSubmitCompliance(e.target.checked, false)}
                            isInvalid={!!privacyError} // Set the isInvalid attribute based on privacyError
                            feedback={privacyError} // Set the feedback attribute to show the error message
                            feedbackType="invalid" // Set feedback type to "invalid"
                        />
                        <Button variant="link" onClick={getPrivacyDocs}>
                            View Privacy Policy
                        </Button>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label="I accept the Terms & Conditions"
                            onChange={(e) => onSubmitCompliance(e.target.checked, true)}
                            isInvalid={!!termsError} // Set the isInvalid attribute based on termsError
                            feedback={termsError} // Set the feedback attribute to show the error message
                            feedbackType="invalid" // Set feedback type to "invalid"
                        />
                        <Button variant="link" onClick={getTermsDocs}>
                            View Terms & Conditions
                        </Button>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 text-end">
                        <Col sm={12}>
                            <Button type="submit">Register</Button>
                        </Col>
                    </Form.Group>

                </Form>

            </MymonXDialog>

            <MymonXDialog
                title="Privacy Policy"
                show={showPrivacy}
                size="lg"
                onHide={() => setShowPrivacy(false)} >
                <div>
                    {complianceResult && complianceResult.length > 0 ? (
                        complianceResult.map((result, index) => (
                            result.compliance_type === 'PDP' ? (
                                <div key={index}>
                                    {/* <iframe
                                        src={base64ToDataUrlas(result.document)}
                                        title={`PDF Document ${index}`}
                                        width="600"
                                        height="800"
                                    /> */}
                                    <embed
                                        src={base64ToDataUrlas(result.document)}
                                        type="application/pdf"
                                        width="100%"
                                        height="600px"
                                    />
                                    {/* <button onClick={() => setShowPrivacy(false)}>Close</button>
                                    <button onClick={() => onSubmitCompliance(false, result.sid)}>Accept</button> */}
                                </div>
                            ) : null // Optionally render nothing or a different element if the compliance_type is not 'PAC'
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}


                </div>
            </MymonXDialog>
            <MymonXDialog
                title="Terms & Conditions"
                show={showTerms}
                size="lg"
                onHide={() => setShowTerms(false)}>
                <div>
                    {complianceResult && complianceResult.length > 0 ? (
                        complianceResult.map((result, index) => (
                            result.compliance_type === 'TAC' ? (
                                <div key={index}>
                                    <iframe
                                        src={base64ToDataUrlas(result.document)}
                                        title={`PDF Document ${index}`}
                                        width="600"
                                        height="800"
                                    />
                                    {/* {result.document} Adjust this to the correct property if necessary */}
                                    {/* <button onClick={() => setShowTerms(false)}>Close</button> */}
                                    {/* <button onClick={() => onSubmitCompliance(true, result.sid)}>Accept</button> */}
                                </div>
                            ) : null // Optionally render nothing or a different element if the compliance_type is not 'PAC'
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}

                </div>

            </MymonXDialog>
        </>
    )
}

export default Dashboard;