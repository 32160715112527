import { Navigate, useLocation } from "react-router-dom";
import LocalStorageService from "../../../infrastructure/local-storage-service";
import { RootState } from "../../../application/store";
import { useAppSelector } from "../../../application/store/useStore";

export interface ProtectedRouteProps {
    children: JSX.Element;
    allowedRoles: string[]; // Allowed roles for the route
}
// Mock function to get user role, you can replace this with actual auth logic

const ProtectedRoute = ({ children, allowedRoles }: ProtectedRouteProps) => {
    const validUser = LocalStorageService.getAccessToken();
    const { currentUser } = useAppSelector((state: RootState) => state.securityService)
    const location = useLocation();
    if (!validUser || !currentUser || !allowedRoles.includes(currentUser.roles[0].name))
        return <Navigate to="/auth/login" state={{ from: location }} />;
    return children;
}

export default ProtectedRoute;