import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../application/store/useStore";
import MymonXValidation from "../../../../components/mymonx-validation";
import '../style.css';
import { UserProfileDto } from "../../../../../application/features/user-profile-service/models/user-profile-dto";
import { updateProfileClientAsync } from "../../../../../application/features/user-profile-service/userProfileServiceThunk";
import { UserProfileResultDto } from "../../../../../application/features/user-profile-service/models/user-profile-result-dto";
import { RootState } from "../../../../../application/store";
import { useEffect, useState } from "react";
import { getCountriesAsync } from "../../../../../application/features/staticData-service/saticDataServiceThunk";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MymonXReqAsteric from "../../../../components/mymonx-req-asteric";
import { getCareTeamsAsync } from "../../../../../application/features/users-service/usersServiceThunk";
import { CareTeamDto } from "../../../../../application/features/users-service/models/care-team-dto";
import ContactForm from "../contact-form";
import { CreateContactDto } from "../../../../../application/features/contact-service/models/create-contact-dto";
import { deleteContactsAsync, getContactsAsync, updateContactsAsync } from "../../../../../application/features/contact-service/contactServiceThunk";
import { setContactList, setSelectedContact } from "../../../../../application/features/contact-service/contactServiceSlice";

interface UpdateClientUserFormProps {
    onHideUpdateForm?(): void;
    onCompleteUpdateForm?(): void;
}

const UpdateClientUserForm = ({ onHideUpdateForm, onCompleteUpdateForm }: UpdateClientUserFormProps) => {
    const dispatch = useAppDispatch();
    const { contry_data } = useAppSelector((state: RootState) => state.staticDataService);
    const { care_teams } = useAppSelector((state: RootState) => state.usersService);
    const { user_profile } = useAppSelector((state: RootState) => state.userProfileService);
    const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date(Date.now()));
    const [setupDate, setSetupDate] = useState<Date | undefined>(undefined);
    const { contactList } = useAppSelector((state: RootState) => state.contactService);
    const [originalContactList, setOriginalContactList] = useState<CreateContactDto[]>([]); // State to keep a copy of the original contact list

    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<UserProfileDto>({
        defaultValues: { ...user_profile }
    });

    useEffect(() => {
        dispatch(getCountriesAsync({ page: 1, per_page: 100 }))
        dispatch(getCareTeamsAsync())
    }, [dispatch])


    useEffect(() => {

        if (user_profile) {

            setValue('sid', user_profile.sid);
            setValue('email', user_profile.email);
            setValue('first_name', user_profile.first_name);
            setValue('last_name', user_profile.last_name);
            setValue('mobile', user_profile.mobile);
            setValue('mobile_prefix', user_profile.mobile_prefix);
            setValue('date_of_birth', user_profile.date_of_birth);
            setDateOfBirth(user_profile.date_of_birth);
            setValue('gender', user_profile.gender);
            setValue('weight', user_profile.weight);
            setValue('weight_unit', user_profile.weight_unit);
            setValue('height', user_profile.height);
            setValue('height_unit', user_profile.height_unit);
            setValue('location', user_profile.location);
            setValue('health_status', user_profile.health_status);
            setValue('nationality', user_profile.nationality);
            setValue('location', user_profile.location);
            setValue('care_team', user_profile.care_team);
            setValue('org_id', user_profile.org_id);
            setValue('user_sid', user_profile.user_sid);
            setValue('serial_number', user_profile.serial_number);
            setValue('setup_date', user_profile.setup_date);
            setValue('time_zone', user_profile.time_zone);
            setSetupDate(user_profile.setup_date ?? new Date());
            dispatch(getContactsAsync(user_profile.sid)).then((res) => {
                if (Array.isArray(res.payload)) { // Check if payload is an array
                    const contacts = res.payload as CreateContactDto[]; // Extract the payload
                    setOriginalContactList(contacts); // Set the original contact list
                } else {
                    // Handle the error case
                    console.error("Failed to fetch contacts:", res.payload);
                }
            });
        }
    }, [dispatch, user_profile])


    const onSubmit = (request: UserProfileDto) => {
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        request.time_zone = clientTimezone;
        dispatch(updateProfileClientAsync(request)).then(res => {
            if (res && res.payload) {
                const profile_id = (res.payload as UserProfileResultDto).user_profile_id;
                if (profile_id) {
                    const updatedContactList = contactList.map(contact => {
                        return { ...contact, user_sid: profile_id };
                    });
                    if (JSON.stringify(originalContactList) !== JSON.stringify(updatedContactList)) { // Check if the lists are different
                        dispatch(updateContactsAsync(updatedContactList)).then(res => {
                            if (res.payload) {
                                if (onCompleteUpdateForm) {
                                    onCompleteUpdateForm();
                                }
                            }
                        });
                    } else {
                        if (onCompleteUpdateForm) {
                            onCompleteUpdateForm();
                        }
                    }
                }
            }
        });
    }

    const handleDateOfBirthChange = (dateChange: Date) => {
        setValue("date_of_birth", dateChange, {
            shouldDirty: true
        });
        setDateOfBirth(dateChange);
    };

    const handleSetupDateChange = (dateChange: Date) => {
        setValue("setup_date", dateChange, {
            shouldDirty: true
        });
        setSetupDate(dateChange);
    };

    const handleDelete = (sid: string) => {
        const updatedContactList = contactList.filter(contact => contact.sid !== sid);
        dispatch(setContactList(updatedContactList));
        dispatch(deleteContactsAsync(sid));
    };

    const handleUpdate = (editContact: CreateContactDto) => {
        dispatch(setSelectedContact(editContact));
    };

    return (
        <>
            <Form id="client-create-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={12} md={12} lg={6}>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.email`}>
                            <Form.Label column sm={12} md={4}>
                                Email<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="email"
                                    {...register('email', {
                                        required: {
                                            value: true,
                                            message: 'email is required'
                                        }
                                    })}
                                    className={`${!!errors.email ? 'has-validation' : ''}`}
                                />
                                <MymonXValidation fieldError={errors.email} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                            <Form.Label column sm={12} md={4}>
                                First Name<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="first name"
                                    {...register('first_name', {
                                        required: {
                                            value: true,
                                            message: 'first name is required'
                                        }
                                    })}
                                    className={`${!!errors.first_name ? 'has-validation' : ''}`}
                                />
                                <MymonXValidation fieldError={errors.first_name} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.last_name`}>
                            <Form.Label column sm={12} md={4}>
                                Last Name<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="last name"
                                    {...register('last_name', {
                                        required: {
                                            value: true,
                                            message: 'last name is required'
                                        }
                                    })}
                                />
                                <MymonXValidation fieldError={errors.last_name} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.date_of_birth`}>
                            <Form.Label column sm={12} md={4}>
                                Date of Birth<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Controller
                                    name="date_of_birth"
                                    control={control}
                                    defaultValue={dateOfBirth}
                                    render={() => (
                                        <DatePicker
                                            className="form-control"
                                            dateFormat={'dd/MM/yyyy'}
                                            maxDate={moment().toDate()}
                                            selected={dateOfBirth}
                                            placeholderText="dd/mm/yyyy"
                                            onChange={handleDateOfBirthChange}
                                        />
                                    )}
                                />
                                <MymonXValidation fieldError={errors.date_of_birth} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.gender`}>
                            <Form.Label column sm={12} md={4}>
                                Gender<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Select aria-label="Default select example"
                                    {...register('gender', {
                                        required: {
                                            value: true,
                                            message: 'gender is required'
                                        }
                                    })}
                                >
                                    <option value="" selected>Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </Form.Select>
                                <MymonXValidation fieldError={errors.gender} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.mobile`}>
                            <Form.Label column sm={12} md={4}>
                                Mobile Number<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Row>
                                    <Col sm="4">
                                        <Form.Control
                                            type="text"
                                            placeholder="+44"
                                            {...register('mobile_prefix', {
                                                required: {
                                                    value: true,
                                                    message: 'prefix mobile is required'
                                                }
                                            })}
                                        />
                                        <MymonXValidation fieldError={errors.mobile_prefix} ></MymonXValidation>
                                    </Col>
                                    <Col sm="8">
                                        <Form.Control
                                            type="text"
                                            placeholder="mobile number"
                                            {...register('mobile', {
                                                required: {
                                                    value: true,
                                                    message: 'mobile is required'
                                                }
                                            })}
                                        />
                                        <MymonXValidation fieldError={errors.mobile} ></MymonXValidation>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.health_status`}>
                            <Form.Label column sm={12} md={4}>
                                Health Status
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Select aria-label="Default select example"
                                    {...register('health_status', {
                                        // required: {
                                        //     value: true,
                                        //     message: 'health status is required'
                                        // }
                                    })}
                                >
                                    <option value="" selected>Select Health Status</option>
                                    <option value="high risk">High Risk</option>
                                    <option value="low risk">Low Risk</option>
                                </Form.Select>
                                {/* <MymonXValidation fieldError={errors.health_status} ></MymonXValidation> */}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.nationality`}>
                            <Form.Label column sm={12} md={4}>
                                Nationality:<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Controller
                                    name="nationality"
                                    control={control}
                                    rules={{ required: "nationality is required" }}

                                    render={({ field }) => (
                                        <Form.Select {...field} aria-label="Default select nationality">
                                            <option>Select Nationality</option>
                                            {contry_data.map((item: any) => (
                                                <option key={item.sid} value={item.iso_name}>{item.iso_name}</option>
                                            ))}

                                        </Form.Select>
                                    )}
                                />
                                <MymonXValidation fieldError={errors.nationality} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.height`}>
                            <Form.Label column sm={12} md={4}>
                                Height<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Row>
                                    <Col sm="8">
                                        <Form.Control
                                            type="text"
                                            placeholder="height"
                                            {...register('height', {
                                                required: {
                                                    value: true,
                                                    message: 'height is required'
                                                }
                                            })}
                                        />
                                        <MymonXValidation fieldError={errors.height} ></MymonXValidation>
                                    </Col>
                                    <Col sm="4">
                                        <Form.Select aria-label="Default select example"
                                            {...register('height_unit', {
                                                required: {
                                                    value: true,
                                                    message: 'height unit is required'
                                                }
                                            })}
                                        >
                                            <option value="" selected>Select Height Unit</option>
                                            <option value="m/cm">m/cm</option>
                                            <option value="ft/in">ft/in</option>
                                        </Form.Select>
                                        <MymonXValidation fieldError={errors.height_unit} ></MymonXValidation>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.weight`}>
                            <Form.Label column sm={12} md={4}>
                                Weight<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Row>
                                    <Col sm="8">
                                        <Form.Control
                                            type="text"
                                            placeholder="weight"
                                            {...register('weight', {
                                                required: {
                                                    value: true,
                                                    message: 'weight is required'
                                                }
                                            })}
                                        />
                                        <MymonXValidation fieldError={errors.weight} ></MymonXValidation>
                                    </Col>
                                    <Col sm="4">
                                        <Form.Select aria-label="Default select example"
                                            {...register('weight_unit', {
                                                required: {
                                                    value: true,
                                                    message: 'weight unit is required'
                                                }
                                            })}
                                        >
                                            <option value="" selected>Select Weight Unit</option>
                                            <option value="kg">kg</option>
                                            <option value="lbs">lbs</option>
                                        </Form.Select>
                                        <MymonXValidation fieldError={errors.weight_unit} ></MymonXValidation>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.location`}>
                            <Form.Label column sm={12} md={4}>
                                Location:<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Controller
                                    name="location"
                                    control={control}
                                    rules={{ required: "location is required" }}
                                    render={({ field }) => (
                                        <Form.Select {...field} aria-label="Default select location">
                                            <option>Select Location</option>
                                            {contry_data.map((item: any) => (
                                                <option key={item.sid} value={item.iso_name}>{item.iso_name}</option>
                                            ))}
                                        </Form.Select>
                                    )}
                                />
                                <MymonXValidation fieldError={errors.location} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.care_team`}>
                            <Form.Label column sm={12} md={4}>
                                Care Team:
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Controller
                                    name="care_team"
                                    control={control}
                                    // rules={{ required: "care team is required" }}
                                    render={({ field }) => (
                                        <Form.Select {...field} aria-label="Default select care team">
                                            <option>Select Care Team</option>
                                            {care_teams && care_teams.map((item: CareTeamDto) => (
                                                <option key={item.sid} value={item.sid}>{item.name}</option>
                                            ))}

                                        </Form.Select>
                                    )}
                                />
                                {/* <MymonXValidation fieldError={errors.care_team} /> */}
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={12} lg={6}>
                        <Row>
                            <Col sm={12} md={12}>
                                <h6 className="mb-3">Add Emergency Contact</h6>
                            </Col>
                            <Col sm={12} md={12}>
                                <ContactForm id="contact-form-update" />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <Table responsive bordered>
                                    <thead >
                                        <tr>

                                            <th>
                                                Name
                                            </th>
                                            <th>
                                                Phone Number
                                            </th>
                                            <th align="center" className="text-center">Actions</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            contactList && contactList.filter((contact: CreateContactDto) => !contact.is_sos)
                                                .map((contact: CreateContactDto, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{`${contact.first_name} ${contact.last_name}`}</td>
                                                            <td>{`${contact.mobile_prefix}-${contact.mobile_number}`}</td>
                                                            <td align="center">
                                                                <span className="pe-2">
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleUpdate(contact)}>
                                                                        <path d="M1 15H2.098L12.796 4.30202L11.698 3.20402L1 13.902V15ZM0 16V13.48L13.18 0.288015C13.2833 0.196682 13.3967 0.126015 13.52 0.0760154C13.6433 0.0260154 13.7723 0.000681818 13.907 1.51514e-05C14.0417 -0.000651515 14.1717 0.020682 14.297 0.0640153C14.4237 0.106015 14.5403 0.182015 14.647 0.292015L15.714 1.36602C15.824 1.47202 15.8993 1.58868 15.94 1.71602C15.98 1.84268 16 1.96935 16 2.09602C16 2.23202 15.9773 2.36202 15.932 2.48602C15.886 2.60935 15.8133 2.72235 15.714 2.82502L2.519 16H0ZM12.238 3.76202L11.698 3.20402L12.796 4.30202L12.238 3.76202Z" fill="#52B2E4" />
                                                                    </svg>
                                                                </span>
                                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete(contact.sid)} >
                                                                    <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#DC3545" />
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                        }
                                        {/* <tr>
                                            <td>Hasnat Adil</td>
                                            <td>034-1234567</td>
                                            <td align="center">

                                                <span className="pe-2">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 15H2.098L12.796 4.30202L11.698 3.20402L1 13.902V15ZM0 16V13.48L13.18 0.288015C13.2833 0.196682 13.3967 0.126015 13.52 0.0760154C13.6433 0.0260154 13.7723 0.000681818 13.907 1.51514e-05C14.0417 -0.000651515 14.1717 0.020682 14.297 0.0640153C14.4237 0.106015 14.5403 0.182015 14.647 0.292015L15.714 1.36602C15.824 1.47202 15.8993 1.58868 15.94 1.71602C15.98 1.84268 16 1.96935 16 2.09602C16 2.23202 15.9773 2.36202 15.932 2.48602C15.886 2.60935 15.8133 2.72235 15.714 2.82502L2.519 16H0ZM12.238 3.76202L11.698 3.20402L12.796 4.30202L12.238 3.76202Z" fill="#52B2E4" />
                                                    </svg>
                                                </span>
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#DC3545" />
                                                </svg>


                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Hasnat Adil</td>
                                            <td>034-1234567</td>
                                            <td align="center">

                                                <span className="pe-2">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 15H2.098L12.796 4.30202L11.698 3.20402L1 13.902V15ZM0 16V13.48L13.18 0.288015C13.2833 0.196682 13.3967 0.126015 13.52 0.0760154C13.6433 0.0260154 13.7723 0.000681818 13.907 1.51514e-05C14.0417 -0.000651515 14.1717 0.020682 14.297 0.0640153C14.4237 0.106015 14.5403 0.182015 14.647 0.292015L15.714 1.36602C15.824 1.47202 15.8993 1.58868 15.94 1.71602C15.98 1.84268 16 1.96935 16 2.09602C16 2.23202 15.9773 2.36202 15.932 2.48602C15.886 2.60935 15.8133 2.72235 15.714 2.82502L2.519 16H0ZM12.238 3.76202L11.698 3.20402L12.796 4.30202L12.238 3.76202Z" fill="#52B2E4" />
                                                    </svg>
                                                </span>
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#DC3545" />
                                                </svg>


                                            </td>
                                        </tr> */}
                                    </tbody>
                                </Table>

                            </Col>
                        </Row>

                        {/*<Row>*/}
                        {/*    <Col sm={12} md={5}>*/}
                        {/*        mymonX Device*/}
                        {/*    </Col>*/}
                        {/*    <Col sm={12} md={7}>*/}
                        {/*        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.serial_number`}>*/}
                        {/*            <Col sm={12} md={12}>*/}
                        {/*                <Form.Label sm={12} md={12}>*/}
                        {/*                    Serial number*/}
                        {/*                </Form.Label>*/}
                        {/*                <Form.Control*/}
                        {/*                    type="text"*/}
                        {/*                    placeholder="Serial number"*/}
                        {/*                    {...register('serial_number', {*/}
                        {/*                        required: {*/}
                        {/*                            value: true,*/}
                        {/*                            message: 'serial number is required'*/}
                        {/*                        }*/}
                        {/*                    })}*/}
                        {/*                    className={`${!!errors.serial_number ? 'has-validation' : ''}`}*/}
                        {/*                />*/}
                        {/*                <MymonXValidation fieldError={errors.serial_number} ></MymonXValidation>*/}
                        {/*            </Col>*/}
                        {/*        </Form.Group>*/}
                        {/*        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.setup_date`}>*/}
                        {/*            <Col sm={12} md={12}>*/}
                        {/*                <Controller*/}
                        {/*                    name="setup_date"*/}
                        {/*                    control={control}*/}
                        {/*                    defaultValue={setupDate}*/}
                        {/*                    render={() => (*/}
                        {/*                        <DatePicker*/}
                        {/*                            dateFormat={'dd/MM/yyyy'}*/}
                        {/*                            maxDate={moment().toDate()}*/}
                        {/*                            selected={setupDate}*/}
                        {/*                            placeholderText="dd/mm/yyyy"*/}
                        {/*                            onChange={handleSetupDateChange}*/}
                        {/*                            className={`form-control ${!!errors.setup_date ? 'has-validation' : ''}`}*/}
                        {/*                        />*/}
                        {/*                    )}*/}
                        {/*                />*/}
                        {/*                <MymonXValidation fieldError={errors.setup_date} ></MymonXValidation>*/}
                        {/*            </Col>*/}
                        {/*        </Form.Group>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Col>
                </Row>
                <Form.Group as={Row} className="mb-3" controlId="formSubmitButtons">
                    <Col sm="12" className="text-end">
                        <Button variant="secondary" type="button" onClick={onHideUpdateForm} >
                            Cancel
                        </Button>{' '}
                        <Button variant="primary" type="submit" >
                            Update
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
        </>
    )
}

export default UpdateClientUserForm;