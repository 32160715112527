import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../common/enums/api-status";
import { getOrgFromJwt } from "../../common/helpers/jwt-helper";
import { BaseState } from "../../common/models/base-state";
import { MapDeviceResultDto } from "./models/MapDeviceResultDto";
import { getDevicesAsync, getDeviceByUserIdAsync, assignDeviceAsync, unAssignDeviceAsync, disableDeviceAsync, GetliveinfoAsync, CreateDeviceOtpAsync, AssignDeviceOtpAsync } from "../device-service/deviceServiceThunk";
import { DeviceApiResponse, DeviceLiveInfo, DeviceResultDto } from "./models/DeviceResultDto";

export interface DeviceServiceState extends BaseState {

    mapDeviceResult: MapDeviceResultDto | null;
    devices: DeviceApiResponse | null;
    device: DeviceResultDto | null;
    liveinfo: DeviceLiveInfo | null;
    OtpResponse: string | undefined;
    initialClientRequest: { org_id: string };

}
const initialClientRequest = {
    org_id: getOrgFromJwt() || ''
}
const initialState: DeviceServiceState = {
    status: ApiStatus.IDLE,
    initialClientRequest: initialClientRequest,
    apiError: undefined, // Assuming BaseState includes apiError
    mapDeviceResult: null, // Initialize as null or appropriate initial value
    devices: null,
    device: null,
    liveinfo: null,
    OtpResponse: ''

}
export const deviceServiceSlice = createSlice({
    name: 'deviceService',
    initialState,
    reducers: {
        // Add reducers here if needed
        setdeviceAssignResult(state, action: PayloadAction<MapDeviceResultDto>) {
            state.mapDeviceResult = action.payload;
        },
        setdeviceUnassignResult(state, action: PayloadAction<MapDeviceResultDto>) {
            state.mapDeviceResult = action.payload;
        },
        setdeviceDisableResult(state, action: PayloadAction<MapDeviceResultDto>) {
            state.mapDeviceResult = action.payload;
        },
        setdevicesResult(state, action: PayloadAction<DeviceApiResponse>) {
            state.devices = action.payload;
        },
        setdeviceResult(state, action: PayloadAction<DeviceResultDto>) {
            state.device = action.payload;
        },


    },
    extraReducers: (builder) => {
        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(assignDeviceAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(assignDeviceAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.mapDeviceResult = action.payload;
            })
            .addCase(assignDeviceAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(unAssignDeviceAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(unAssignDeviceAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.mapDeviceResult = action.payload;
            })
            .addCase(unAssignDeviceAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(disableDeviceAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(disableDeviceAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.mapDeviceResult = action.payload;
            })
            .addCase(disableDeviceAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
            .addCase(getDevicesAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })

            .addCase(getDevicesAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.devices = action.payload;
            })
            .addCase(getDevicesAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
            .addCase(getDeviceByUserIdAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getDeviceByUserIdAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.device = action.payload;
            })
            .addCase(getDeviceByUserIdAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
            .addCase(GetliveinfoAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(GetliveinfoAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.liveinfo = action.payload;
            })
            .addCase(GetliveinfoAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
            .addCase(CreateDeviceOtpAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(CreateDeviceOtpAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.OtpResponse = action.payload;
            })
            .addCase(CreateDeviceOtpAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
            .addCase(AssignDeviceOtpAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(AssignDeviceOtpAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.OtpResponse = action.payload;
            })
            .addCase(AssignDeviceOtpAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
            ;
    }
});

// Export actions
export const { setdeviceAssignResult, setdeviceUnassignResult, setdevicesResult, setdeviceResult } = deviceServiceSlice.actions;

// Export the reducer
export default deviceServiceSlice.reducer;
