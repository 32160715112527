import { Button, Col, Form, Row, Table } from "react-bootstrap";
import MymonXReqAsteric from "../../../../../components/mymonx-req-asteric";
import { Controller, useForm } from "react-hook-form";
import { DeviceSubConfigurationAlarm, Configuration, DeviceConfig, DeviceMainConfiguration, GetConfigurationByUserDto } from "../../../../../../application/features/configuration-service/models/create-configuration-dto";
import { useAppDispatch, useAppSelector } from "../../../../../../application/store/useStore";
import { createConfigurationResultAsync, getConfigurationByUserResultAsync } from "../../../../../../application/features/configuration-service/configurationServiceThunk";
import { useEffect, useState } from "react";
import { RootState } from "../../../../../../application/store";
import { setAlarmsRequest } from "../../../../../../application/features/configuration-service/configurationServiceSlice";
import { addWarningNotification } from "../../../../../../application/features/common/notificationSlice";
import { v4 as uuidv4 } from 'uuid';

interface AlarmConfigurationProps {
    user_sid: string | undefined;
}

const AlarmConfiguration = ({ user_sid }: AlarmConfigurationProps) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dispatch = useAppDispatch();
    const { configurationRequest, alarms } = useAppSelector((state: RootState) => state.configurationService);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const {
        control,
        setValue,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<DeviceSubConfigurationAlarm>({
        defaultValues: {
            sid: "",
            name: "",
            time: "",
            daysOfWeek: Array(7).fill(""),
            repeat: false,
            isMedication: false
        }
    });

    useEffect(() => {
        const _config: GetConfigurationByUserDto = {
            user_sid: user_sid ?? '',
            configuration_name: 'reminder'
        };
        dispatch(getConfigurationByUserResultAsync(_config)).then((action: any) => {
            if (action.payload && action.payload.config && action.payload.config.length > 0) {
                const fetchedAlarms = action.payload.config[0].configuration.alarms;
                dispatch(setAlarmsRequest(fetchedAlarms));
            }
        });
    }, [dispatch, user_sid]);

    const onSubmit = (data: DeviceSubConfigurationAlarm) => {
       
        const checkedDaysIndices = data.daysOfWeek
            .map((checked, index) => (checked ? index : -1))
            .filter(index => index !== -1);

        const checkedDaysValues = checkedDaysIndices.map(index => days[index]);

        data.daysOfWeek = checkedDaysValues;
        const existingIndex = alarms.findIndex(alarm => alarm.sid === data.sid);

        let updatedAlarms;

        if (existingIndex >= 0) {
            // Update existing alarm
            updatedAlarms = alarms.map((alarm, index) =>
                index === existingIndex ? data : alarm
            );
        } else {
            data.sid = uuidv4();
            // Add new alarm
            updatedAlarms = [...alarms, data];
        }

        // Dispatch the updated alarms state
        dispatch(setAlarmsRequest(updatedAlarms));
        setIsEditing(false); // Reset editing state
        reset({
            sid: "",
            name: "",
            time: "",
            daysOfWeek: Array(7).fill(""),
            repeat: false,
            isMedication: false
        });

    };

    const saveAlarms = () => {
        if (user_sid) {
            const _configuration: Configuration = { alarms: alarms };
            const _config: DeviceConfig = {
                sid: '',// uuidv4(), // Assigning a UUID
                name: 'reminder',
                configuration: _configuration
            };
            const _devicemainconfig: DeviceMainConfiguration = {
                user_sid: user_sid ?? '',
                config: [_config]
            };
            dispatch(createConfigurationResultAsync(_devicemainconfig!));
            const _getconfig: GetConfigurationByUserDto = {
                user_sid: user_sid ?? '',
                configuration_name: 'reminder'
            };
            dispatch(getConfigurationByUserResultAsync(_getconfig)).then((action: any) => {
                if (action.payload && action.payload.config && action.payload.config.length > 0) {
                    const fetchedAlarms = action.payload.config[0].configuration.alarms;
                    dispatch(setAlarmsRequest(fetchedAlarms));
                }
            });
            reset({
                time: "",
                daysOfWeek: Array(7).fill(""),
                repeat: false,
                isMedication: false
            });
        } else {
            addWarningNotification("There is no client assigned to this device");
        }
    }

    const handleDelete = (index: number) => {
        const newAlarms = alarms.filter((_, i) => i !== index);
        dispatch(setAlarmsRequest(newAlarms));
    };

    const handleEdit = (index: number) => {
        const alarm = alarms[index];
        setValue("sid", alarm.sid);
        setValue("name", alarm.name);
        setValue("time", alarm.time);
        setValue("daysOfWeek", days.map(day => (alarm.daysOfWeek.includes(day) ? day : "")));
        setValue("repeat", alarm.repeat);
        setValue("isMedication", alarm.isMedication);
        setIsEditing(true); // Set editing state
    };

    return (
        <>
            <div className="card mb-3">
                <div className="bg-body-tertiary card-body">
                    <Form id="alarm-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col sm={6} md={6} lg={6}>
                                <h5 className="mb-3">Alarm Clock</h5>
                            </Col>
                            <Col sm={6} md={6} lg={6}>
                                <div className="col-lg-6 text-end">
                                    <table className="mb-3">
                                        <tr>
                                            <td className="pe-2">Is Medication Alarm </td>
                                            <td>
                                                <Controller
                                                    name="isMedication"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={field.value}
                                                            onChange={field.onChange}
                                                            onBlur={field.onBlur}
                                                            name={field.name}
                                                            ref={field.ref}
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </Col>

                            <Col md={12} lg={3}>
                                <Form.Group as={Row} className="mb-3" controlId={`alarm-form.name`}>
                                    <Form.Label column sm={12} md={12}>
                                        Name <MymonXReqAsteric />
                                    </Form.Label>
                                    <Col sm={12} md={12}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            rules={{ required: "Name is required" }}
                                            render={({ field }) => (
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Name"
                                                    isInvalid={!!errors.name}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name?.message}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={2}>
                                <Form.Group as={Row} className="mb-3" controlId={`alarm-form.time`}>
                                    <Form.Label column sm={12} md={12}>
                                        Time <MymonXReqAsteric />
                                    </Form.Label>
                                    <Col sm={12} md={12}>
                                        <Controller
                                            name="time"
                                            control={control}
                                            rules={{ required: "Time is required" }}
                                            render={({ field }) => (
                                                <Form.Control
                                                    type="time"
                                                    isInvalid={!!errors.time}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.time?.message}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group as={Row} className="mb-3" controlId={`alarm-form.days`}>
                                    <Form.Label column sm={12} md={12}>
                                        Days <MymonXReqAsteric />
                                    </Form.Label>
                                    <Col sm={12} md={12}>
                                        <div className="w-100 justify-content-between d-flex">
                                            {days.map((day, index) => (
                                                <div key={index}>
                                                    <p>{day}</p>
                                                    <Controller
                                                        name={`daysOfWeek.${index}`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Form.Check
                                                                type="checkbox"
                                                                checked={!!field.value}
                                                                onChange={(e) => field.onChange(e.target.checked ? day : "")}
                                                                name={field.name}
                                                                ref={field.ref}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={2}>
                                <Form.Group as={Row} className="mb-3" controlId={`alarm-form.repeat`}>
                                    <Form.Label column sm={12} md={12}>
                                        Repeat <MymonXReqAsteric />
                                    </Form.Label>
                                    <Col sm={12} md={12}>
                                        <Controller
                                            name="repeat"
                                            control={control}
                                            render={({ field }) => (
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    name={field.name}
                                                    ref={field.ref}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={12} className="text-end">
                                <Button variant="primary" type="submit">
                                    {isEditing ? "Update" : "Add"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <Row className="mt-3">
                        <Col>
                            <Table responsive bordered>
                                <thead >
                                    <tr>
                                        <th>Name</th>
                                        <th>Time</th>
                                        <th>Days</th>
                                        <th>Repeat</th>
                                        <th>Is Medication Alarm</th>
                                        <th align="center" className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {alarms.map((alarm, index) => (
                                        <tr key={index}>
                                            <td>{alarm.name}</td>
                                            <td>{alarm.time}</td>
                                            <td>{alarm.daysOfWeek.map((day, i) => day).join(",")}</td>
                                            <td>{alarm.repeat.toString()}</td>
                                            <td>{alarm.isMedication.toString()}</td>
                                            <td align="center">
                                                <span className="pe-2">
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        onClick={() => handleEdit(index)}
                                                    >
                                                        <path
                                                            d="M1 15H2.098L12.796 4.30202L11.698 3.20402L1 13.902V15ZM0 16V13.48L13.18 0.288015C13.2833 0.196682 13.3967 0.126015 13.52 0.0760154C13.6433 0.0260154 13.7723 0.000681818 13.907 1.51514e-05C14.0417 -0.000651515 14.1717 0.020682 14.297 0.0640153C14.4237 0.106015 14.5403 0.182015 14.647 0.292015L15.714 1.36602C15.824 1.47202 15.8993 1.58868 15.94 1.71602C15.98 1.84268 16 1.96935 16 2.09602C16 2.23202 15.9773 2.36202 15.932 2.48602C15.886 2.60935 15.8133 2.72235 15.714 2.82502L2.519 16H0ZM12.238 3.76202L11.698 3.20402L12.796 4.30202L12.238 3.76202Z"
                                                            fill="#52B2E4"
                                                        />
                                                    </svg>
                                                </span>
                                                <svg
                                                    width="16"
                                                    height="18"
                                                    viewBox="0 0 16 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    onClick={() => handleDelete(index)}
                                                >
                                                    <path
                                                        d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                                                        fill="#DC3545"
                                                    />
                                                </svg>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Col md={12} lg={12} className="text-end">
                                <Button variant="primary" type="button" onClick={saveAlarms} >
                                    Save
                                </Button>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default AlarmConfiguration;
