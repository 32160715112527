// serviceWorkerRegistration.ts

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.\d+){0,3}\.\d+$/)
  );
  
  export function register(config?: { onSuccess?: () => void; onError?: (error: Error) => void }) {
    if ('serviceWorker' in navigator) {
      const publicUrl = new URL(process.env.PUBLIC_URL || '', window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        return;
      }
  
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        if (isLocalhost) {
          checkValidServiceWorker(swUrl, config);
        } else {
          registerValidSW(swUrl, config);
        }
      });
    }
  }
  
  function registerValidSW(swUrl: string, config?: { onSuccess?: () => void; onError?: (error: Error) => void }) {
    navigator.serviceWorker.register(swUrl)
      .then((registration) => {
        if (registration && registration.onupdatefound) {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker) {
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                  
                    if (config && config.onSuccess) {
                      config.onSuccess();
                    }
                  } else {
                    
                    if (config && config.onSuccess) {
                      config.onSuccess();
                    }
                  }
                }
              };
            }
          };
        }
      })
      .catch((error) => {
        console.error('Error during service worker registration:', error);
        if (config && config.onError) {
          config.onError(error);
        }
      });
  }
  
  function checkValidServiceWorker(swUrl: string, config?: { onSuccess?: () => void; onError?: (error: Error) => void }) {
    fetch(swUrl, {
      headers: { 'Service-Worker': 'script' }
    })
      .then((response) => {
        const contentType = response.headers.get('content-type');
        if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          registerValidSW(swUrl, config);
        }
      })
      .catch(() => {
       
      });
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
    }
  }
  