import { Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import PortalLayout from "./PortalLayout";
import Login from "../auth/login";
import ForgetPassword from "../auth/forget-password";
import ResetPassword from "../auth/reset-password";
import Register from "../auth/register";
import Dashboard from "../portal/dashboard";
import ClientDetail from "../portal/client-users/client-detail/client-details";
import UserProfile from "../portal/user-profile";
import ProtectedRoute from "./ProtectedRoute";
import RegisterMessage from "../auth/register-message";
import EmailConfirmation from "../auth/email-confirmation";
import CompleteRegisteration from "../auth/complete-registeration";
import Users from "../portal/users";
import ClientUsers from "../portal/client-users";
import ConsumerDashboard from "../consumer/dashboard";
import ConsumerUserProfile from "../consumer/user-profile";
import Sos from "../portal/sos";
import DevicesComp from "../portal/devices";
import RegisterConsumer from "../auth/register-consumer";
import { useAppSelector } from "../../../application/store/useStore";
import { RootState } from "../../../application/store";

const AppRoutes = () => {
    const { currentUser } = useAppSelector((state: RootState) => state.securityService);

    return (
        <Routes>
            <Route element={<AuthLayout />}>
                <Route path="auth">
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    <Route path="register-consumer" element={<RegisterConsumer />} />
                    <Route path="forgot-password" element={<ForgetPassword />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="register-success" element={<RegisterMessage />} />
                    <Route path="email-confirmation" element={<EmailConfirmation />} />
                    <Route path="complete-registeration" element={<CompleteRegisteration />} />
                </Route>
            </Route>
            {/* <Route element={<PortalLayout />}>
                <Route path="portal">
                    <Route path="" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                    <Route path="dashboard">
                        <Route path="" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

                    </Route>


                    <Route path="Client">
                        <Route path="" element={<ProtectedRoute><ClientUsers /></ProtectedRoute>} />
                        <Route path=":id" element={<ProtectedRoute><ClientDetail /></ProtectedRoute>} />
                    </Route>

                    <Route path="Devices">
                        <Route path="" element={<ProtectedRoute><DevicesComp /></ProtectedRoute>} />
                    </Route>
                    <Route path="users">
                        <Route path="" element={<ProtectedRoute><Users /></ProtectedRoute>} />
                    </Route>
                    <Route path="user-profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
                    <Route path="sos" element={<ProtectedRoute><Sos /></ProtectedRoute>} />
                </Route>
            </Route> */}
            {/* Admin Portal Routes */}
            <Route element={<PortalLayout />}>
                <Route path="portal">
                    <Route path="" element={<ProtectedRoute allowedRoles={['org_admin']}><Dashboard /></ProtectedRoute>} />
                    <Route path="dashboard" element={<ProtectedRoute allowedRoles={['org_admin']}><Dashboard /></ProtectedRoute>} />

                    <Route path="Client">
                        <Route path="" element={<ProtectedRoute allowedRoles={['org_admin']}><ClientUsers /></ProtectedRoute>} />
                        <Route path=":id" element={<ProtectedRoute allowedRoles={['org_admin']}><ClientDetail /></ProtectedRoute>} />
                    </Route>

                    <Route path="Devices" element={<ProtectedRoute allowedRoles={['org_admin']}><DevicesComp /></ProtectedRoute>} />
                    <Route path="users" element={<ProtectedRoute allowedRoles={['org_admin']}><Users /></ProtectedRoute>} />
                    <Route path="user-profile" element={<ProtectedRoute allowedRoles={['org_admin']}><UserProfile /></ProtectedRoute>} />
                    <Route path="sos" element={<ProtectedRoute allowedRoles={['org_admin']}><Sos /></ProtectedRoute>} />
                </Route>
            </Route>
            {/* Consumer Routes */}
            <Route element={<PortalLayout />}>
                <Route path="consumer">
                    <Route path="" element={<ProtectedRoute allowedRoles={['consumer', 'org_client']}><ConsumerDashboard /></ProtectedRoute>} />
                    <Route path="dashboard" element={<ProtectedRoute allowedRoles={['consumer', 'org_client']}><ConsumerDashboard /></ProtectedRoute>} />
                </Route>
            </Route>
            <Route
                path="*"
                element={
                    currentUser?.roles[0].name === 'org_admin' ? (
                        <ProtectedRoute allowedRoles={[currentUser?.roles[0].name || '']}>
                            <Navigate to="/portal/dashboard" replace />
                        </ProtectedRoute>
                    ) : (
                        <ProtectedRoute allowedRoles={[currentUser?.roles[0].name || '']}>
                            <Navigate to="/consumer/dashboard" replace />
                        </ProtectedRoute>
                    )
                }
            />
        </Routes>
    )
}

export default AppRoutes;