
import { PaginatedResult } from "../application/common/models/paginated-result-dto";
import { InviteClientDto } from "../application/features/security-service/models/invite-client-dto";
import { CreateUserDto } from "../application/features/user-profile-service/models/create-user-dto";
import { ClientDashboardStats, UserProfileDto, UserProfileImageDto, UserProfileImageResultDto, UserUploadImageResultDto } from "../application/features/user-profile-service/models/user-profile-dto";
import { UserAlertGridDto, UserProfileGridDto } from "../application/features/user-profile-service/models/user-profile-grid-dto";
import { UserAlertRequestDto, UserOrgAlertRequestDto, UserProfileRequestDto } from "../application/features/user-profile-service/models/user-profile-request-dto";
import { UserProfileResultDto } from "../application/features/user-profile-service/models/user-profile-result-dto";
import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api"

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);
const getProfiles = (request: UserProfileRequestDto) => {
    const filter = `&filter_by=${request.filter_by === undefined ? '' : request.filter_by}`
    const org_id = `&org_id=${request.org_id === undefined ? '' : request.org_id}`
    return organisationPortalApi.get<PaginatedResult<UserProfileGridDto>>(`/api/v0/user/profiles?page_number=${request.page_number}&page_size=${request.page_size}${filter}${org_id}`);
}


const getHighRiskProfiles = (orgId: string | undefined) => {
    const org_id = `org_id=${orgId === undefined ? '' : orgId}`
    return organisationPortalApi.get<UserProfileGridDto[]>(`/api/v0/user/profiles_high_risk?${org_id}`);
}


const getProfile = (sid: string) => {
    return organisationPortalApi.get<UserProfileDto>(`/api/v0/user/profiles/${sid}`);
}
const getProfileByEmail = (email: string) => {
    return organisationPortalApi.get<UserProfileDto>(`/api/v0/user/profiles/email/${email}`);
}
const createProfile = (user_profile: UserProfileDto) => {
    return organisationPortalApi.post<UserProfileResultDto>("/api/v0/user/profiles", user_profile);
}

const createProfileClient = (user_profile: UserProfileDto) => {
    return organisationPortalApi.post<UserProfileResultDto>("/api/v0/user/profiles/client", user_profile);
}

const createProfileUser = (user_profile: CreateUserDto) => {
    return organisationPortalApi.post<UserProfileResultDto>("/api/v0/user/profiles/user", user_profile);
}

const updateProfile = (user_profile: UserProfileDto) => {
    return organisationPortalApi.put<UserProfileResultDto>("/api/v0/user/profiles", user_profile);
}

const updateProfileClient = (user_profile: UserProfileDto) => {
    return organisationPortalApi.put<UserProfileResultDto>("/api/v0/user/profiles/client", user_profile);
}

const deleteProfile = (sid: string) => {
    return organisationPortalApi.delete<boolean>(`/api/v0/user/profiles/${sid}`);
}

const getClientStats = (org_id: string | undefined) => {
    return organisationPortalApi.get<ClientDashboardStats>(`/api/v0/user/profiles_stats?org_id=${org_id}`);
}

const getClientAlerts = (request: UserAlertRequestDto) => {
    const filter = `&filter_by=${request.filter_by === undefined ? '' : request.filter_by}`
    const org_id = `&org_id=${request.org_id === undefined ? '' : request.org_id}`
    const user_id = `&user_id=${request.user_id === undefined ? '' : request.user_id}`
    return organisationPortalApi.get<PaginatedResult<UserAlertGridDto>>(`/api/v0/user/alerts/client?page_number=${request.page_number}&page_size=${request.page_size}${filter}${org_id}${user_id}`);
}

const getOrgClientAlerts = (request: UserOrgAlertRequestDto) => {
    const filter = `&filter_by=${request.filter_by === undefined ? '' : request.filter_by}`
    const org_id = `&org_id=${request.org_id === undefined ? '' : request.org_id}`
    return organisationPortalApi.get<PaginatedResult<UserAlertGridDto>>(`/api/v0/user/alerts/org?page_number=${request.page_number}&page_size=${request.page_size}${filter}${org_id}`);
}
const enableLogin = (model: InviteClientDto) => {
    return organisationPortalApi.post<any>("/api/v0/user/profiles/enable-login", model);
}
const uploadUserImage = (userProfileImage: UserProfileImageDto) => {
    return organisationPortalApi.patch<UserUploadImageResultDto>("/api/v0/user/profiles/avatar", userProfileImage);
}

const getUserProfileImage = (user_profile_sid: string) => {
    return organisationPortalApi.get<UserProfileImageResultDto>(`/api/v0/user/profiles/avatar?user_profile_sid=${user_profile_sid}`);
}
const UserProfileServiceApi = {
    getProfiles,
    getProfile,
    createProfile,
    updateProfile,
    deleteProfile,
    getClientStats,
    getHighRiskProfiles,
    updateProfileClient,
    createProfileClient,
    createProfileUser,
    getClientAlerts,
    getOrgClientAlerts,
    enableLogin,
    uploadUserImage,
    getUserProfileImage,
    getProfileByEmail
}

export default UserProfileServiceApi;