import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import { deleteClientAsync, getClientsAsync } from "../../../../application/features/client-service/clientServiceThunk";
import { RootState } from "../../../../application/store";
import MymonXDialog from "../../../components/mymonx-dialog";
import MymonXConfirmationDialog from "../../../components/mymonx-confirmation-dialog";
import { Row, Col, InputGroup, FormControl, Table, Form, Stack, Badge } from "react-bootstrap";
import MymonXPagination from "../../../components/mymonx-pagination";
import { appConstants } from "../../../../application/common/constants/app-constant";
import { Link } from "react-router-dom";
import CreateClientUserForm from "./create-update-form/create-client-user-form";
import { deleteUserProfileAsync, getProfileAsync, getProfilesAsync } from "../../../../application/features/user-profile-service/userProfileServiceThunk";
import { UserProfileGridDto } from "../../../../application/features/user-profile-service/models/user-profile-grid-dto";
import { capitalizeWords } from "../../../../application/common/helpers/text-helper";
import { setProfileRequest } from "../../../../application/features/user-profile-service/userProfileServiceSlice";
import UpdateClientUserForm from "./create-update-form/update-client-user-form";
import MapDeviceForm from "./map-device-form/map-device-form";
import InviteClientForm from "./invite-client-form/invite-client-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faMobileAlt } from "@fortawesome/free-solid-svg-icons";


const ClientUsers = () => {
  const dispatch = useAppDispatch();
  const { user_profiles, userProfileRequest } = useAppSelector((state: RootState) => state.userProfileService);
  const [showPersonForm, setShowPersonForm] = useState<boolean>(false);
  const [showClientEditForm, setShowClientEditForm] = useState<boolean>(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showAssignDialog, setShowAssignDialog] = useState<boolean>(false);
  const [showInviteDialog, setShowInviteDialog] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<UserProfileGridDto | null>(null);

  const _handleSearch = _.debounce((search: string) => {
    dispatch(setProfileRequest({ ...userProfileRequest, filter_by: search }))
  }, 1500, { maxWait: 1500 });

  useEffect(() => {
    if (userProfileRequest) {
      dispatch(getProfilesAsync(userProfileRequest));
    }
  }, [dispatch, userProfileRequest]);

  const onDeleteHide = () => {
    setShowDeleteDialog(false);
  }
  const onAssignHide = () => {
    setShowAssignDialog(false);
  }
  const onDeleteConfirm = () => {
    var request = { ...selectedRow };

    if (request.sid) {
      dispatch(deleteUserProfileAsync(request.sid)).then(res => {
        if (res && res.payload && res.payload) {
          if ((res.payload as boolean) === true) {
            setShowDeleteDialog(false);
            dispatch(getProfilesAsync(userProfileRequest));
          }
        }
      });
    }
  }
  // const handleCheckboxChange = (row: any) => {
  //   setSelectedRow(row);

  // };
  const handleCheckboxChange = (row: any) => {

    if (selectedRow?.sid === row.sid) {
      setSelectedRow(null); // Unselect the row if it is already selected
    } else {
      setSelectedRow(row); // Select the new row
    }

  }
 
  const onPaginationChange = (page: number) => {
    dispatch(setProfileRequest({ ...userProfileRequest, page_number: page }));
  }

  const onRecordPerPageChange = (page_size: number) => {
    dispatch(setProfileRequest({ ...userProfileRequest, page_number: appConstants.GRID_DEFAULT_PAGE_NUMBER, page_size: page_size }));
  }


  const getHealthStatus = (healthStatus: string) => {
    let status = 'primary';
    if (healthStatus.toLowerCase() === 'low risk') {
      status = 'success';
    } else if (healthStatus.toLowerCase() === 'high risk') {
      status = 'danger';
    } else {
      status = 'warning';
    }

    return status;
  }

  const editForm = () => {
    
    if (selectedRow?.sid) {
      dispatch(getProfileAsync(selectedRow.sid));
      setShowClientEditForm(true)
    }
  }
  const renderMobileAppIcon = (status: string) => {

    let stat = status ? status.toLowerCase() : '';

    switch (stat) {
      case 'invited':
        return <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'blue' }} title="Invited" />;
      case 'completed':
        return <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'green' }} title="Completed" />;

      default:
        return null;
    }
  };

  return (
    <>
      <div className="Cleintsmain">
        <Row className="mb-3">
          <Col sm md="6">
            <div>
              <button className='btn btn-success me-2 mb-2' onClick={() => setShowPersonForm(true)}>New Client</button>
              <button className='btn btn-primary me-2 mb-2' disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false} onClick={editForm}>Modify Client</button>
              <button className='btn btn-danger me-2 mb-2' disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false} onClick={() => setShowDeleteDialog(true)}>Delete Client</button>
              <button className='btn btn-danger me-2 mb-2' disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false} onClick={() => setShowAssignDialog(true)}>Assign Device</button>
              <button className='btn btn-danger me-2 mb-2' disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false} onClick={() => setShowInviteDialog(true)}>Invite for Mobile App</button>

            </div>
          </Col>
          <Col sm md="6">
            <InputGroup>
              <FormControl
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="Search..."
                onChange={(e) => _handleSearch(e.target.value)}
              />
              <div className="search-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
            </InputGroup>
          </Col>
        </Row>

        <Table responsive bordered>
          <thead key={`client-header`}>
            <tr key={`client-header-row`}>
              <th></th>
              <th>
                Name
              </th>
              <th>
                Email
              </th>
              <th>
                Date of Birth
              </th>
              <th>
                Health Status
              </th>
              <th>
                Care Location
              </th>

            </tr>
          </thead>
          <tbody key={`client-body`}>
            {user_profiles && user_profiles.data &&
              user_profiles?.data.map((row: UserProfileGridDto, index: number) => (
                <tr key={`row-${index}`}>
                  <td key={`action-${index}`}>
                    <Form>
                      <Form.Check
                        type={"checkbox"}
                        id={`default-checkbox${row.sid}`}
                        checked={row.sid === selectedRow?.sid}
                        onChange={() => handleCheckboxChange(row)}
                        className="custom-checkbox"
                      />
                    </Form>
                  </td>
                  <td key={`name-${index}`}>
                    <Link to={row.sid} > {
                      `${row.name}`
                    } </Link>
                    {renderMobileAppIcon(row.mobile_app_status)}
                  </td>
                  <td key={`email-${index}`}>

                    {`${row.email}`}

                  </td>
                  <td key={`date-of-birth-${index}`}>
                    {`${row.date_of_birth} (${row.years})`}
                  </td>
                  <td key={`health-status-${index}`}>
                    <Stack direction="horizontal" gap={2}>
                      <Badge bg={getHealthStatus(row.health_status)} key={`health-status-badge-${index}`}>
                        {capitalizeWords(row.health_status)}
                      </Badge>
                    </Stack>
                  </td>
                  <td key={`location-${index}`}>
                    {row.care_location}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {
          user_profiles && <MymonXPagination
            totalPages={user_profiles.total_pages}
            currentPage={user_profiles.page}
            recordPerPage={user_profiles.per_page}
            onPageChange={onPaginationChange}
            onRecordPerPageChange={onRecordPerPageChange}
          />
        }

        <MymonXDialog
          title="Client Form"
          show={showPersonForm}
          size="xl"
          onHide={() => setShowPersonForm(false)}
        >
          <CreateClientUserForm
            onHide={() => setShowPersonForm(false)}
            onComplete={() => {
              setShowPersonForm(false);
              dispatch(getProfilesAsync(userProfileRequest));
            }}
            emailconsumer=""
          />
        </MymonXDialog>

        <MymonXDialog
          title="Update Client"
          show={showClientEditForm}
          size="xl"
          onHide={() => setShowClientEditForm(false)}
        >
          <UpdateClientUserForm
            onHideUpdateForm={() => setShowClientEditForm(false)}
            onCompleteUpdateForm={() => {
              setShowClientEditForm(false);
              dispatch(getProfilesAsync(userProfileRequest));
            }}
          />
        </MymonXDialog>
        <MymonXDialog
          title="Map Device"
          show={showAssignDialog}
          size="lg"
          onHide={() => setShowAssignDialog(false)}
        >
          <MapDeviceForm
            onHideUpdateForm={() => setShowAssignDialog(false)}
            onCompleteUpdateForm={() => {
              setShowAssignDialog(false);
              dispatch(getProfilesAsync(userProfileRequest));
            }}
            user_profile={selectedRow ?? undefined}
          />
        </MymonXDialog>
        <MymonXDialog
          title="Invite to Mobile App"
          show={showInviteDialog}
          size="lg"
          onHide={() => setShowInviteDialog(false)}
        >
          <InviteClientForm
            onHideUpdateForm={() => setShowInviteDialog(false)}
            onCompleteUpdateForm={() => {
              setShowInviteDialog(false);
              dispatch(getProfilesAsync(userProfileRequest));
            }}
            user_profile={selectedRow ?? undefined}
          />
        </MymonXDialog>

        <MymonXConfirmationDialog
          show={showDeleteDialog}
          title="Delete Client"
          description={`Are you sure you want to delete client ${selectedRow?.name}`}
          cancelButtonText="Cancel"
          confirmButtonText="Confirm"
          onHide={onDeleteHide}
          onConfirm={onDeleteConfirm}
        />
      </div>
    </>
  );
}

export default ClientUsers;