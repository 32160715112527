import { Button, Card, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/logo.svg";

export interface RegisterMessageProps {
    IsConsumer: boolean;
}

const RegisterMessage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { IsConsumer } = location.state as RegisterMessageProps; // Extract IsConsumer from the route state

    return (
        <>
            <Row className="h-100">
                <Col className="col-12" >
                    <Row className="justify-content-end">
                        <Col className="col-lg-4 col-md-5 text-end">
                            <a href="#"><img className="img-fluid" src={logo} alt="Logo" /></a>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-12" >
                    <Row>
                        <Col className="col-lg-4 col-md-6">
                            <Card >
                                <Card.Body>
                                    {IsConsumer ? (
                                        <>
                                            <h3 className="text-center my-3">Registration Successful</h3>
                                            <p className="my-3">
                                                Thank you for registering  on mymonX. We look forward to working with you and seeing the improved health benefits to your clients.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="text-center my-3">Registration Successful</h3>
                                            <p className="my-3">
                                                Thank you for registering as a Organisation on mymonX. We look forward to working with you and seeing the improved health benefits to your clients.
                                            </p>
                                            <p className="my-3">
                                                You will shorty receive an email to confirm your registration. On receiving the email click the link and it will take you to the page to complete your Organisations registration. You must complete the Organisation Registration in order to use the solution.
                                            </p>
                                        </>
                                    )

                                    }

                                    <div className="text-center">
                                        <Button as="input" className="w-100 mb-3" variant="primary" type="button" value="Continue" onClick={() => navigate('/auth/login')} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default RegisterMessage;