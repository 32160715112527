import { Button, CloseButton, Col, Form, Modal, Row, Table } from "react-bootstrap"
import React, { useEffect, useState } from 'react';
import { UserSosModelDto } from "../../../application/features/sos-service/models/user-sos-model-dto";
import { useAppDispatch, useAppSelector } from "../../../application/store/useStore";
import { closeSosResultAsync, getGpsResultAsync, getSosResultAsync, getVitalsResultAsync, saveNotesResultAsync } from "../../../application/features/sos-service/sosServiceThunk";
import { RootState } from "../../../application/store";
import { getProfileAsync } from "../../../application/features/user-profile-service/userProfileServiceThunk";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { CloseRequestDto, NotesRequestDto } from "../../../application/features/sos-service/models/gps-request-dto";

interface GoogleMapProps {
    latitude: number;
    longitude: number;
}
const GoogleMap = ({ latitude, longitude }: GoogleMapProps) => {
    //const mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.77010258674!2d-0.09004262369746874!3d51.517433609970645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d85328ad7d9%3A0xad92a9a04cc22be8!2smymonX!5e0!3m2!1sen!2s!4v1717670113834!5m2!1sen!2s";
    const mapSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.77010258674!2d${longitude - 0.012}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d85328ad7d9%3A0xad92a9a04cc22be8!2smymonX!5e0!3m2!1sen!2s!4v1717670113834!5m2!1sen!2s`;

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <iframe
                src={mapSrc}
                width="100%"
                height="100%"
                style={{ border: 0 }}

                loading="lazy"
            ></iframe>
        </div>
    );
};
interface CustomModalProps {
    show: boolean;
    handleClose: () => void;
    sosModel: UserSosModelDto;
}

const CustomModal = ({ show, handleClose, sosModel }: CustomModalProps) => {
    const [minimized, setMinimized] = useState(false);
    const handleMinimize = () => setMinimized(!minimized);
    const { sosResult, gpsResult, vitalsResult, notesResult, closeResult } = useAppSelector((state: RootState) => state.sosService);
    const { user_profile } = useAppSelector((state: RootState) => state.userProfileService);
    const { currentUser } = useAppSelector((state: RootState) => state.securityService);

    const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date(Date.now()));
    const dispatch = useAppDispatch();
    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm<NotesRequestDto>({
        defaultValues: {
            sos_sid: undefined,
            sos_notes: sosModel.notes,
        }
    });
    useEffect(() => {

        const requestParams = {
            sos_sid: sosModel.sos_id,
            from_date: sosModel.from_date, // Adjust this if you have a specific from_date
            page_number: 1,
            page_size: 10
        };
        const fetchData = async () => {
            try {
                dispatch(getGpsResultAsync(requestParams));
                dispatch(getVitalsResultAsync(requestParams));
                dispatch(getProfileAsync(sosModel.user_sid));
            } catch (error) {
                console.error("Error fetching SOS data:", error);
            }
        };
        fetchData();

        // Set interval
        const interval = setInterval(fetchData, 60000); // 30 seconds interval

        // Cleanup on unmount
        return () => clearInterval(interval);
    }, [dispatch]);

    // Check if vitalsResult is not null or undefined, then sort it
    const sortedVitalsResult = (vitalsResult && Array.isArray(vitalsResult))
        ? vitalsResult.slice().sort((a, b) => moment(b.vitals_date_time).valueOf() - moment(a.vitals_date_time).valueOf())
        : [];  // Return an empty array if vitalsResult is null or not an array

    // Check if gpsResult is not null or undefined, then sort it
    const sortedGpsResult = (gpsResult && Array.isArray(gpsResult))
        ? gpsResult.slice().sort((a, b) => moment(b.gps_date_time).valueOf() - moment(a.gps_date_time).valueOf())
        : [];  // Return an empty array if gpsResult is null or not an array
    const onSubmit = (notesRequest: NotesRequestDto) => {
        notesRequest.sos_sid = sosModel.sos_id;
        notesRequest.user_sid = currentUser?.id || '';
        dispatch(saveNotesResultAsync(notesRequest)).then((res: any) => {
            if (res.payload) {

            }
        })
    };
    const completeSos = () => {
        const request: CloseRequestDto = {
            sos_sid: sosModel.sos_id,
            user_sid: currentUser?.id || '',
        };
        dispatch(closeSosResultAsync(request)).then((res: any) => {
            if (res.payload) {
          
                handleClose(); // Call handleClose if needed

            }
        });
    };
    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
                Emergency Alert
            </Button> */}

            <Modal
                show={show}
                size="xl"
                onHide={handleClose}
                centered
                dialogClassName={minimized ? 'minimized-modal' : ''}
                backdrop={false} // No backdrop for better UX when minimized
            >
                <Modal.Header>

                    <Modal.Title>Emergency Alert</Modal.Title>
                    <div className="ms-auto">
                        {/*<Button variant="secondary" onClick={handleMinimize}>*/}
                        {/*    {minimized ? 'Maximize' : 'Minimize'}*/}
                        {/*</Button>*/}
                        <Button variant="close" onClick={handleClose}></Button>
                    </div>
                </Modal.Header>
                {!minimized && (
                    <Modal.Body>


                        <div className="row">

                            <div className="col-lg-9 col-md-12">

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-lg-3">
                                                <h6 className="mb-2">{`${user_profile?.first_name} , ${user_profile?.last_name}`} </h6>
                                                <p className="mb-1"> {moment(user_profile?.date_of_birth).format('DD-MM-yyyy')} {(moment().diff(user_profile?.date_of_birth, 'years'))}</p>
                                            </div>
                                            <div className="col-lg-3">
                                                <h6 className="mb-2">Known Medical Codnitions </h6>
                                                <p className="mb-1">medical conditions will show here</p>

                                            </div>
                                            <div className="col-lg-3">
                                                <h6 className="mb-2">Current Medication</h6>
                                                <p className="mb-1">medications will show here</p>
                                            </div>
                                            {/* <div className="col-lg-3 text-end">

                                                <img src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250" className="img-fluid w-50" />  
                                             </div>*/}

                                        </div>
                                    </div>

                                </div>




                                <div className="row">

                                    <div className="col-lg-6 col-md-12">
                                        <div className="card mb-3">
                                            <div className="card-body text-center bg-body-secondary">
                                                <h4 className="mb-3">Vitals</h4>
                                                <div className="row">

                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="card mb-3">
                                                            <div className="card-body text-center">
                                                                <h6 className="mb-2">Heart Rate</h6>
                                                                <p className="mb-1">{`${sortedVitalsResult[0]?.vitals_hr}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="card mb-3">
                                                            <div className="card-body text-center">
                                                                <h6 className="mb-2">Temperature</h6>
                                                                <p className="mb-1">{`${sortedVitalsResult[0]?.vitals_temp}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="card mb-3">
                                                            <div className="card-body text-center">
                                                                <h6 className="mb-2">Blood Oxygen</h6>
                                                                <p className="mb-1">{`${sortedVitalsResult[0]?.vitals_spo2}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="card mb-3">
                                                            <div className="card-body text-center">
                                                                <h6 className="mb-2">Blood Pressure</h6>
                                                                <p className="mb-1">{`${sortedVitalsResult[0]?.vitals_bp}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="card mb-3">
                                                            <div className="card-body text-center">
                                                                <h6 className="mb-2">Respiratory Rate</h6>
                                                                <p className="mb-1">{`${sortedVitalsResult[0]?.vitals_RR}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="card mb-3">
                                                            <div className="card-body text-center">
                                                                <h6 className="mb-2">Stress</h6>
                                                                <p className="mb-1">{`${sortedVitalsResult[0]?.vitals_stress}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                    <div className="col-lg-6 col-md-12">

                                        {sortedGpsResult && sortedGpsResult.length > 0 && sortedGpsResult[0] ? (
                                            <GoogleMap latitude={sortedGpsResult[0].gps_latitude} longitude={sortedGpsResult[0].gps_longitude} />
                                        ) : (
                                            <p>No GPS data available</p>
                                        )}
                                        {/* <GoogleMap latitude={gpsResult[0].gps_latitude} longitude={gpsResult[0].gps_longitude} /> */}

                                    </div>


                                </div>




                                <div className="row mt-3">

                                    <div className="col-md-12 text-center">
                                        <h4>Vitals</h4>
                                        <Table responsive bordered>
                                            <thead>
                                                <tr>
                                                    <th>Time</th>
                                                    <th>Heart Rate</th>
                                                    <th>Temperatue</th>
                                                    <th>Blood Oxygen</th>
                                                    <th>Blood Pressure</th>
                                                    <th>Respiratory Rate</th>
                                                    <th>Stress</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedVitalsResult && sortedVitalsResult.map((vital, index) => (
                                                    <tr key={index}>
                                                        <td>{moment(vital.vitals_date_time).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                        <td>{vital.vitals_hr} bpm</td>
                                                        <td>{vital.vitals_temp} &deg;C</td>
                                                        <td>{vital.vitals_spo2}%</td>
                                                        <td>{vital.vitals_bp}</td>
                                                        <td>{vital.vitals_RR}</td>
                                                        <td>{vital.vitals_stress}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>


                                    </div>





                                </div>



                            </div>

                            <div className="col-lg-3 col-md-12">

                                <div className="card">
                                    <div className="card-body bg-body-secondary">


                                        <Form onSubmit={handleSubmit(onSubmit)} id="descriptionForm">
                                            <Form.Group as={Row} className="mb-3" controlId="descriptionForm.description">
                                                <Form.Label column sm={5} md={7}>
                                                    Notes:

                                                </Form.Label>
                                                <Col sm={12} md={12}>
                                                    <Controller
                                                        name="sos_notes"
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Form.Control
                                                                as="textarea"
                                                                onChange={onChange}
                                                                value={value}
                                                                placeholder="Enter Notes here..."
                                                                rows={20}
                                                            />
                                                        )}
                                                    />

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Col sm={{ span: 10, offset: 2 }} md={{ span: 7, offset: 5 }}>
                                                    <Button type="submit">Save Note</Button>
                                                </Col>
                                            </Form.Group>
                                        </Form>
                                    </div>

                                </div>




                            </div>

                        </div>

                    </Modal.Body>
                )}
                {!minimized && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={completeSos}>
                            Complete
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
};

export default CustomModal;