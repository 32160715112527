import Chart from 'react-apexcharts';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../../../../application/store/useStore';
import { RootState } from '../../../../../../../application/store';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { resetSleepVitalsGraph, setVitalsGraphRequest } from '../../../../../../../application/features/health-service/healthServiceSlice';
import { GraphFilterDto } from '../../../../../../../application/features/health-service/models/graph-filter-dto';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getsleepVitalsGraphAsync } from '../../../../../../../application/features/health-service/healthServiceThunk';
import { VitalsGraphRequestDto } from '../../../../../../../application/features/health-service/models/graph-request-dto';
import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import { SleepData, SleepRecord } from '../../../../../../../application/features/health-service/models/bp-graph-dto';
import HighchartsReact from 'highcharts-react-official';
import HighchartsXRange from 'highcharts/modules/xrange';
import Highcharts, { AxisLabelsFormatterContextObject } from 'highcharts';

interface serData {
    x: number;
    x2: number;
    y: number;
    color: string;
    borderColor: string;
    dataLabels: { enabled: boolean };
}; // Explicitly typing `serData`

interface SeriesData {
    name: string;
    data: {
        x: number;
        x2: number;
        y: number;
        color: string;
        borderColor: string;
        dataLabels: {
            enabled: boolean;
        };
    }[];
}


interface SleepGraphProps {
    id: string;
    userId: string;
}
// Define the type for color mappings
export interface ColorMappings {
    [key: string]: string; // Allows any string as a key
}

const SleepGraph = ({ id, userId }: SleepGraphProps) => {
    const dispatch = useAppDispatch();
    const vitalsGraphRequest = useAppSelector((state: RootState) => state.healthService.vitalsGraphRequest['SLEEP']);
    const { sleepDataDto } = useAppSelector((state: RootState) => state.healthService);
    const graphFilterDto = useAppSelector((state: RootState) => state.healthService.graphFilterDto);
    const { organisation } = useAppSelector((state: RootState) => state.orgService);
    const [startDate, setStartDate] = useState<Date | null>(moment().startOf('day').toDate());//moment(moment().diff(6, 'days')).startOf('day').toDate());
    const [endDate, setEndDate] = useState<Date | null>(moment().endOf('day').toDate());
    const [selectedPeriod, setSelectedPeriod] = useState('DAY');
    const [seriesData, setSeriesData] = useState<serData[]>([]);
    const [sleepData, SetSleepData] = useState<SleepRecord[]>([]);
    const [summarydate, SetSummaryDate] = useState<string[]>([]);

    const [chartData, setChartData] = useState<{
        awake: { x: number; y: number }[];
        light: { x: number; y: number }[];
        deep: { x: number; y: number }[];
    }>({ awake: [], light: [], deep: [] });
    // Initialize the xrange module
    HighchartsXRange(Highcharts);
    const categories = [
        '<img src="./assets/awake.png"  style="width: 16px; height: 16px;"> Awake',
        '<img src="./assets/light.png"  style="width: 16px; height: 16px;"> Light',
        '<img src="./assets/deep.png"  style="width: 16px; height: 16px;"> Deep'
    ];
    const {
        control,
        handleSubmit,
        setValue,
    } = useForm<VitalsGraphRequestDto>({
        defaultValues: {
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            vital_type: 'SLEEP'

        }
    });

    useEffect(() => {
        dispatch(resetSleepVitalsGraph(''))
    }, [dispatch]);

    const handleFilterClick = (period: any) => {
        if (userId) {
            let start_date = moment().startOf('day').toDate();
            let end_date = moment().endOf('day').toDate();
            let graphType = 'DAY';

            switch (period) {
                case 'DAY':
                    start_date = moment().startOf('day').toDate();
                    graphType = 'DAY';
                    break;
                case 'WEEK':
                    start_date = moment().subtract(6, 'day').startOf('day').toDate();
                    graphType = 'WEEK';
                    break;
                case 'MONTH':
                    start_date = moment().subtract(29, 'day').startOf('day').toDate();
                    graphType = 'MONTH';
                    break;
                case 'YEAR':
                    start_date = moment().subtract(364, 'day').startOf('day').toDate();
                    graphType = 'YEAR';
                    break;
                case 'RANGE':
                    graphType = 'RANGE';

                    break;
                default:
                    break;
            }

            setStartDate(start_date);
            setEndDate(end_date);
            setSelectedPeriod(period);

            if (graphType !== 'RANGE') {
                const request = {
                    userId: userId,
                    vital_type: vitalsGraphRequest.vital_type,
                    graphType: graphType,
                    startDate: moment(start_date).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(end_date).format('YYYY-MM-DD HH:mm:ss'),
                };
                dispatch(setVitalsGraphRequest({ vital_type: request.vital_type, request }));

            }
        }

    };

    useEffect(() => {
        // Reset to initial state when component mounts
        setStartDate(moment().startOf('day').toDate());
        setEndDate(moment().endOf('day').toDate());
        setSelectedPeriod('DAY');

        const defaultRequest = {
            userId: userId,
            vital_type: 'SLEEP',
            graphType: 'DAY',
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch(setVitalsGraphRequest({ vital_type: defaultRequest.vital_type, request: defaultRequest }));

        return () => {
            dispatch(resetSleepVitalsGraph('SLEEP'));
        };
    }, [dispatch, userId]);



    useEffect(() => {
        fetchGraphData();
    }, [dispatch, vitalsGraphRequest])



    const fetchGraphData = () => {
        if (vitalsGraphRequest && vitalsGraphRequest.userId !== undefined && vitalsGraphRequest.userId !== '') {
            const startDate = new Date(vitalsGraphRequest.startDate);
            const endDate = new Date(vitalsGraphRequest.endDate);
            dispatch(getsleepVitalsGraphAsync(vitalsGraphRequest));
        }
    };
    const handleStartDateChange = (dateChange: Date) => {
        setValue("startDate", moment(dateChange).startOf('day').format('YYYY-MM-DD HH:mm:ss'), {
            shouldDirty: true
        });
        setStartDate(dateChange);
    };

    const handleEndDateChange = (dateChange: Date) => {
        setValue("endDate", moment(dateChange).endOf('day').format('YYYY-MM-DD HH:mm:ss'), {
            shouldDirty: true
        });
        setEndDate(dateChange);
    };

    const onSubmit = (requestObj: VitalsGraphRequestDto) => {

        const sDate = moment(requestObj.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const eDate = moment(requestObj.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        if (userId) {

            const request = {
                userId: userId,
                vital_type: requestObj.vital_type,
                graphType: selectedPeriod,
                startDate: sDate,
                endDate: eDate,
            };
            dispatch(setVitalsGraphRequest({ vital_type: requestObj.vital_type, request }));

        }

    }
    const minMaxFormatter = ({ startDate, endDate }: { startDate: string; endDate: string }, timezone: string) => {

        const fDate = new Date(startDate);
        const tDate = new Date(endDate);
        if (vitalsGraphRequest.graphType === 'DAY') {
            return {
                min: new Date(fDate.getFullYear(), fDate.getMonth(), fDate.getDate(), 0, 0, 0, 0).getTime(),
                max: new Date(tDate.getFullYear(), tDate.getMonth(), tDate.getDate(), 23, 59, 59, 999).getTime(),
            };
        } else if (vitalsGraphRequest.graphType === 'WEEK' || vitalsGraphRequest.graphType === 'MONTH' || vitalsGraphRequest.graphType === 'YEAR') {
            return {
                min: new Date(fDate.getFullYear(), fDate.getMonth(), fDate.getDate()).getTime(),
                max: new Date(tDate.getFullYear(), tDate.getMonth(), tDate.getDate()).getTime(),
            };
        } else if (vitalsGraphRequest.graphType === 'RANGE') {
            const fromDate = new Date(vitalsGraphRequest.startDate);
            const endDate = new Date(vitalsGraphRequest.endDate);

            const diffDays = moment(vitalsGraphRequest.endDate).diff(moment(vitalsGraphRequest.startDate), 'days');

            if (moment(vitalsGraphRequest.startDate).isSame(vitalsGraphRequest.endDate, 'day')) {
                // If the range is only for one day, include the full day with time
                return {
                    min: new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0, 0).getTime(),
                    max: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999).getTime(),
                };
            } else if (diffDays > 1 && diffDays <= 365) {
                // If the range spans more than a day and up to a year, use just the day without time
                return {
                    min: new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()).getTime(),
                    max: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()).getTime(),
                };
            } else {
                // For other cases (beyond a year), also remove time part
                return {
                    min: new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()).getTime(),
                    max: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()).getTime(),
                };
            }
        } else {
            return {
                min: new Date(fDate.getFullYear(), fDate.getMonth(), fDate.getDate()).getTime(),
                max: new Date(tDate.getFullYear(), tDate.getMonth(), tDate.getDate()).getTime(),
            };
        }
    };

    const formattedMinMax = minMaxFormatter({
        startDate: vitalsGraphRequest.startDate,
        endDate: vitalsGraphRequest.endDate
    }, organisation?.timezone || 'UTC'); // Pass the specific timezone
    const colorMappings: ColorMappings = {
        DEEP: '#3498db',
        LIGHT: '#e74c3c',
        AWAKE: '#14cc3f'
    };
    useEffect(() => {
        if (sleepDataDto && sleepDataDto.length > 0) {
            const categoryMapping = { 'DEEP': '#D100DC', 'LIGHT': '#D28FF7', 'AWAKE': '#FFC105' };
            const serObj: {
                x: number;
                x2: number;
                y: number;
                color: string;
                borderColor: string;
                dataLabels: { enabled: boolean };
            }[] = []; // Explicitly typing `serData`
            const categories: string[] = [];
            sleepDataDto.forEach((summary, index) => {
                categories.push(summary.summary.sleep_datetime);
                const dataPoints = summary.summary.data.map(item => {
                    const start_datetime = moment.utc(item.timestamp).tz(organisation?.timezone || 'UTC');

                    // Print in the desired format: YYYY-MM-DD HH:mm:ss
                    console.log("With Timezone:", start_datetime.format('YYYY-MM-DD HH:mm:ss'), "UTC:", moment.utc(item.timestamp).format('YYYY-MM-DD HH:mm:ss'));

                    // Calculate total minutes since midnight
                    const start = start_datetime.hour() * 60 + start_datetime.minute();// * 60 * 1000;
                    // const start = new Date(item.timestamp).getTime();
                    const end = start + item.duration * 60 * 1000;
                    const color = categoryMapping[item.category as keyof typeof categoryMapping];
                    return {
                        x: start,
                        x2: end,
                        y: index, // Place each summary on a separate bar
                        color: color,
                        borderColor: color,
                        dataLabels: {
                            enabled: false // Disable labels on the bar
                        }
                    };
                });

                serObj.push(...dataPoints);
            });
            SetSummaryDate(categories);
            setSeriesData(serObj); // Set the series data
        }
    }, [dispatch, sleepDataDto]);


    // Define chart options
    const chartOptions = {
        chart: {
            type: 'xrange'
        },
        title: {
            text: 'Sleep Stages'
        },
        xAxis: {
            title: {
                text: 'Time'
            },
            min: 0,                   // Start of the day (in minutes)
            max: 1439,             // End of the day (1440 minutes = 24 hours)
            tickInterval: 60,         // One tick per hour (60 minutes)
            labels: {
                formatter: function (this: AxisLabelsFormatterContextObject) {
                    // Format the label to display time in HH:mm
                    const hours = Math.floor(this.value as number / 60);
                    const minutes = this.value as number % 60;
                    return Highcharts.dateFormat('%H:%M', Date.UTC(1970, 0, 1, hours, minutes));
                }
            },
            dateTimeLabelFormats: {
                hour: '%H:%M',
                minute: '%H:%M'
            }
        },
        yAxis: {
            title: {
                text: 'Date'
            },
            categories: summarydate,  // Unique sleep dates as categories
            reversed: true,           // Most recent sleep periods on top
            labels: {
                useHTML: true,
                formatter: function (this: AxisLabelsFormatterContextObject): string {
                    const date = new Date(this.value as number).getTime();
                    return Highcharts.dateFormat('%Y-%m-%d', date);

                }

            },
            lineWidth: 1
        },
        legend: {
            enabled: false
        },
        // tooltip: {
        //     enabled: false // Disables the tooltip
        // },
        tooltip: {
            formatter: function (this: any): string {
                // Start time in minutes (x value)
                const start = this.point.x;
                const duration = this.point.x2 - this.point.x; // Duration in minutes
                // Calculate end time in minutes
                const end = start + duration / (60 * 1000); // Convert duration from milliseconds to minutes
                // Convert start (minutes) to hours and minutes
                const startHours = Math.floor(start / 60);
                const startMinutes = start % 60;

                // Convert end (minutes) to hours and minutes
                const endHours = Math.floor(end / 60);
                const endMinutes = end % 60;

                // Format times as HH:mm
                const startTimeFormatted = Highcharts.dateFormat('%H:%M', Date.UTC(1970, 0, 1, startHours, startMinutes));
                const endTimeFormatted = Highcharts.dateFormat('%H:%M', Date.UTC(1970, 0, 1, endHours, endMinutes));

                // Return the formatted string for the tooltip
                return `<strong>Sleep Stage:</strong><br/>${startTimeFormatted} - ${endTimeFormatted}`;
            }
        },

        series: [{
            pointWidth: 80,
            borderRadius: 5,
            data: seriesData
        }]
    };


    // Create the chart
    // Highcharts.chart('container', chartOptions);
    const isGraphDataEmpty = (data: any): boolean => {
        return data === null || data === undefined;
    };
    const formatDuration = (totalMinutes: number) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours} hour(s) ${minutes} minute(s)`;
    };
    const formatDurationFromSeconds = (totalSeconds: number) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours} hour(s) ${minutes} minute(s)  ${seconds} second(s)`;
    };
    return (
        <>
            <div><h2>Sleep </h2></div>
            <div className="d-flex">
                <ButtonGroup>
                    {
                        graphFilterDto && graphFilterDto.map((item: GraphFilterDto, index: number) => {
                            return (
                                <Button
                                    key={`btn-${item.key}-${index}`}
                                    style={{ margin: '1 rem' }}
                                    onClick={() => handleFilterClick(item.value)}
                                    variant={selectedPeriod === item.key ? 'primary' : 'secondary'}
                                >
                                    {item.key}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>

                {
                    selectedPeriod === 'RANGE' ? <>
                        <form className="ms-2 d-flex" id="filter-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue={moment(startDate).format('YYYY-MM-DD')}
                                render={() => (
                                    <DatePicker
                                        className="form-control"
                                        dateFormat={'dd/MM/yyyy'}
                                        maxDate={moment().toDate()}
                                        selected={startDate}
                                        placeholderText="dd/mm/yyyy"
                                        onChange={handleStartDateChange}
                                    />
                                )}
                            />
                            {false && (
                                <Controller
                                    name="endDate"
                                    control={control}
                                    defaultValue={moment(endDate).format('YYYY-MM-DD')}
                                    render={() => (
                                        <DatePicker
                                            className="form-control"
                                            dateFormat={'dd/MM/yyyy'}
                                            maxDate={moment().toDate()}
                                            selected={endDate}
                                            placeholderText="dd/mm/yyyy"
                                            onChange={handleEndDateChange}
                                        />
                                    )}
                                />
                            )}

                            <Button variant="primary" type="submit" >
                                Filter
                            </Button>
                        </form>
                    </> : <>

                    </>
                }
            </div>
            {
                sleepData && sleepDataDto && sleepDataDto.length > 0 && isGraphDataEmpty(sleepData[0]?.summary.deep_duration || []) ?
                    (<div className="col-12 text-center">
                        <div className="alert alert-warning" role="alert">
                            No data found
                        </div>
                    </div>
                    ) :
                    (
                        <>

                            {
                                sleepData && <div>

                                    <div id="chart">
                                        {/* <Chart options={options} series={series} type="bar" height={350} /> */}
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chartOptions}
                                        />
                                    </div>
                                    <div id="html-dist"></div>
                                </div>
                            }

                            {
                                <div className="row">
                                    {/* Awake Card */}
                                    <div key='awake' className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">
                                        <div className="card shadow-sm">
                                            <div className="card-header">
                                                <div className="align-items-center d-flex justify-content-between">
                                                    <h5>Awake</h5>

                                                </div>
                                            </div>
                                            <div className="card-body" style={{ backgroundColor: '#14cc3f' }}>
                                                <div className="row">
                                                    <div key='col-awake' className="col-md-12 text-center">
                                                        <p className="small mb-0 bold-heading-large-text" >Total Duration</p>
                                                        <small className="bold-large-text">{formatDuration(
                                                            sleepData.reduce((acc: number, curr: SleepRecord) => {
                                                                return acc + (curr?.summary.awake_duration ?? 0);
                                                            }, 0))}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Deep Sleep Card */}
                                    <div key='deep-sleep' className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">
                                        <div className="card shadow-sm">
                                            <div className="card-header">
                                                <div className="align-items-center d-flex justify-content-between">
                                                    <h5>Deep Sleep</h5>
                                                </div>
                                            </div>
                                            <div className="card-body" style={{ backgroundColor: '#3498db' }}>
                                                <div className="row" >
                                                    <div key='col-deep' className="col-md-12 text-center" >
                                                        <p className="small mb-0 bold-heading-large-text">Total Duration</p>
                                                        <small className="bold-large-text">{formatDuration(
                                                            sleepData.reduce((acc: number, curr: SleepRecord) => {
                                                                return acc + (curr?.summary.deep_duration ?? 0);
                                                            }, 0))}
                                                        </small>
                                                        {/* <div className="border p-3 rounded-3 text-center" >
                                                          
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Light Sleep Card */}
                                    <div key='light-sleep' className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">
                                        <div className="card shadow-sm">
                                            <div className="card-header">
                                                <div className="align-items-center d-flex justify-content-between">
                                                    <h5>Light Sleep</h5>
                                                </div>
                                            </div>
                                            <div className="card-body" style={{ backgroundColor: '#e74c3c' }}>
                                                <div className="row">
                                                    <div key='col-light' className="col-md-12 text-center">
                                                        <p className="small mb-0 bold-heading-large-text">Total Duration</p>
                                                        <small className="bold-large-text">{formatDuration(
                                                            sleepData.reduce((acc: number, curr: SleepRecord) => {
                                                                return acc + (curr?.summary.light_duration ?? 0);
                                                            }, 0))}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                        </>
                    )
            }
        </>
    )
}

export default SleepGraph;