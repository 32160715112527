import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import MymonXValidation from "../../../components/mymonx-validation";
import { Controller, ValidateResult, useForm } from "react-hook-form";
import MymonXReqAsteric from "../../../components/mymonx-req-asteric";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import { RootState } from "../../../../application/store";
import { useLocation, useNavigate } from "react-router-dom";
import { ResetPasswordDto } from "../../../../application/features/security-service/models/forgot-password-dto";
import { resetPasswordAsync } from "../../../../application/features/security-service/securityServiceThunk";
import logo from "../../../../assets/images/logo.svg";
import { useEffect } from "react";

const ResetPassword = () => {
    const { apiError } = useAppSelector((state: RootState) => state.securityService);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm<ResetPasswordDto>({
        defaultValues: {
            confirmation_token: '',
            password: '',
            confirm_password: "",
        }
    })

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        if (token) {
            setValue('confirmation_token', token);
        }
    }, [location.search]);

    const onSubmit = (resetCredentials: ResetPasswordDto) => {
        dispatch(resetPasswordAsync(resetCredentials)).then((res: any) => {
            if (res && res.payload) {
                navigate('/auth/login')
            }
        })
    };

    const validatePassword = (password: string): ValidateResult => {
        if (password.length < 8)
            return 'Password should be minimum 8 char length';
        if (!/[A-Z]+/.test(password))
            return 'Password must have one upper case';
        if (!/[a-z]+/.test(password))
            return 'Password must have one lower case';
        if (!/[0-9]+/.test(password))
            return 'Password must have one number';
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))
            return 'Password must have one special char';
    };

    const validateConfirmPassword = (confirmPassword: string): ValidateResult => {
        if (watch("password") !== confirmPassword)
            return 'Password and confirm password should be same';
    };
    return (
        <Row className="h-100">
            {
                apiError && apiError.status === 401 && (
                    <>
                        <Alert security="error">{apiError.error}</Alert>
                    </>
                )
            }
            <Col className="col-12" >
                <Row className="justify-content-end">
                    <Col className="col-lg-4 col-md-5 text-end">
                        <a href="#"><img className="img-fluid" src={logo} alt="Logo" /></a>
                    </Col>
                </Row>
            </Col>

            <Col className="col-12" >
                <Row>
                    <Col className="col-lg-4 col-md-6">
                        <Card >
                            <Card.Body>
                                <h3 className="text-center my-3">Register</h3>
                                <Form onSubmit={handleSubmit(onSubmit)} id="resetForm" noValidate>
                                    <Form.Group className="mb-3" controlId="resetForm.Password">
                                        <Form.Label>Password <MymonXReqAsteric /></Form.Label>
                                        <Controller
                                            name="password"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'password is required'
                                                },
                                                validate: (value: string) => validatePassword(value)
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Control type="password" onChange={onChange} value={value} placeholder="*******" />
                                            )}
                                        />
                                        <MymonXValidation fieldError={errors.password} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="resetForm.ConfirmPassword">
                                        <Form.Label>Verify Password <MymonXReqAsteric /></Form.Label>
                                        <Controller
                                            name="confirm_password"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'confirm password is required'
                                                },
                                                validate: (value: string) => validateConfirmPassword(value)
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Form.Control type="password" onChange={onChange} value={value} placeholder="*******" />
                                            )}
                                        />
                                        <MymonXValidation fieldError={errors.confirm_password} />
                                    </Form.Group>
                                    <Button as="input" className="w-100 mb-3" variant="primary" type="submit" value="Reset Password" />{' '}
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Col>


        </Row>
    )
}

export default ResetPassword;