import { useParams } from 'react-router-dom';
// import OxygenGraph from './oxygen-graph';
// import TempGraph from './temp-graph';
// import GlucoseGraph from './glucose-graph';
// import RespiratoryRateGraph from './rr-graph'
import { useEffect, useState } from 'react';
import React, { Suspense, lazy } from 'react';
import SleepGraph from './sleep-graph';
// import BpGraph from './bp-graph';
// import HealthGraph from './health-graph';
// import EcgGraph from './ecg-graph';
// import StepGraph from './step-graph';
// import SleepMobileGraph from './sleep-graph-mobile';

const OxygenGraph = lazy(() => import('./oxygen-graph'));
const TempGraph = lazy(() => import('./temp-graph'));
const GlucoseGraph = lazy(() => import('./glucose-graph'));
const RespiratoryRateGraph = lazy(() => import('./rr-graph'));
const BpGraph = lazy(() => import('./bp-graph'));
const HealthGraph = lazy(() => import('./health-graph'));
const EcgGraph = lazy(() => import('./ecg-graph'));
const StepGraph = lazy(() => import('./step-graph'));
const SleepMobileGraph = lazy(() => import('./sleep-graph-mobile'));

interface HealthMetricsPageProps {
    id?: string;
}


const HealthMetricsPage = ({ id }: HealthMetricsPageProps) => {

    const [refreshKey, setRefreshKey] = useState(0);

    return (
        <>
            {id && (
                <Suspense fallback={<div>Loading...</div>}>
                    <HealthGraph key={`health-graph-${id}-${refreshKey}`} id={`health-graph-${id}`} userId={id} />
                    <BpGraph key={`bp-graph-${id}-${refreshKey}`} id={`bp-graph-${id}`} userId={id} />
                    <OxygenGraph key={`oxygen-graph-${id}-${refreshKey}`} id={`oxygen-graph-${id}`} userId={id} />
                    <TempGraph key={`temp-graph-${id}-${refreshKey}`} id={`temp-graph-${id}`} userId={id} />
                    <GlucoseGraph key={`glucose-graph-${id}-${refreshKey}`} id={`glucose-graph-${id}`} userId={id} />
                    <RespiratoryRateGraph key={`respiratory-graph-${id}-${refreshKey}`} id={`respiratory-graph-${id}`} userId={id} />
                    <EcgGraph key={`ecg-graph-${id}-${refreshKey}`} id={`ecg-graph-${id}`} userId={id} />
                    <StepGraph key={`step-graph-${id}-${refreshKey}`} id={`step-graph-${id}`} userId={id} />
                    <SleepGraph key={`sleep-graph-${id}-${refreshKey}`} id={`sleep-graph-${id}`} userId={id} />
                </Suspense>
            )}
        </>

    );
};

export default HealthMetricsPage;