import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import { RootState } from "../../../../application/store";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import logo from "../../../../assets/images/logo.svg";
import { ForgotPasswordDto } from "../../../../application/features/security-service/models/forgot-password-dto";
import { forgotPasswordAsync } from "../../../../application/features/security-service/securityServiceThunk";

const ForgetPassword = () => {
    const { currentUser, apiError } = useAppSelector((state: RootState) => state.securityService);
    const dispatch = useAppDispatch();

    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm<ForgotPasswordDto>({
        defaultValues: {
            username: ''
        }
    })
    const onSubmit = (forgotCredentials: ForgotPasswordDto) => {
        dispatch(forgotPasswordAsync(forgotCredentials));
    };

    return (
        <>
            <Row className="h-100">
                <Col className="col-12" >
                    <Row className="justify-content-end">
                        <Col className="col-lg-4 col-md-5 text-end">
                            <a href="#"><img className="img-fluid" src={logo} alt="Logo" /></a>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-12" >
                    <Row>
                        <Col className="col-lg-4 col-md-6">
                            <Card >
                                <Card.Body>
                                    <h3 className="text-center my-3">Login</h3>
                                    <Form onSubmit={handleSubmit(onSubmit)} id="loginForm">
                                        <Form.Group className="mb-3" controlId="loginForm.username">
                                            <Form.Label>Email address</Form.Label>
                                            <Controller
                                                name="username"
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'username is required'
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control type="email" onChange={onChange} value={value} placeholder="name@example.com" />
                                                )}
                                            />

                                        </Form.Group>

                                        <Button as="input" className="w-100 mb-3" variant="primary" type="submit" value="Submit" />{' '}
                                    </Form>
                                    <div className="text-center">
                                        <a href="/auth/login" className="text-center text-decoration-none my-2 d-block fs-5">Login</a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default ForgetPassword;